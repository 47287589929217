/* eslint-disable */
import Vue from "vue";
import VueMoment from "vue-moment";
import Toast from "vue-toastification";
import VCalendar from "v-calendar";
import money from 'v-money'
import JsonExcel from "vue-json-excel";
import Dialog from "@/plugins/dialog";

import router from "./router";
import store from "./store";
import App from "./App.vue";

import "vue-toastification/dist/index.css";
import 'sweetalert2/dist/sweetalert2.min.css';
import filt from "./filters";

Vue.config.productionTip = false;

Vue.use(VueMoment);
Vue.use(Dialog);

Vue.use(Toast, {
  timeout: 5000,
  icon: false
});

Vue.use(VCalendar, {
  componentPrefix: "vc"
});

Vue.use(money, {
  precision: 2,
  decimal: ',',
  thousands: '.',
})

Vue.component("downloadExcel", JsonExcel);

new Vue({
  router,
  store,
  render: h => h(App),
  filters: filt
}).$mount("#app");
