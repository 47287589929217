<template>
  <div>
    <PageTitle :breadcrumbName="breadcrumbName" :pageTitle="pageTitle" class="mb-2"/>
    <div class="card flex fd-c fgap2">

      <div class="flex ai-c jc-sb">
        <div>
          <h4 class="text-uppercase text-medium mb-1">Processos de Importação</h4>
          <p class="text-light">Quantidade de Processos de Importação</p>
          <!-- <p class="text-light">Quantidade de Processos de Importação (Cliente: Todos os clientes) </p> -->
        </div>
        <!--
                <div>
                  <select name="clientes" id="clientes" class="rounded offwhite-bg no-border text-bold">
                    <option value="">TODOS OS CLIENTES</option>
                  </select>
                </div> -->
      </div>

      <div class="medium-border-radius light-bordered offwhite-bg p-2 columns gap2">
        <StepCard :processes="stepsAmount.Produção" :stepNumber="1" class="span-2"
                  stepName="Produção" @clicked="stepClick(7)"/>
        <StepCard :processes="stepsAmount.Booking" :stepNumber="2" class="span-2"
                  stepName="Booking" @clicked="stepClick(1)"/>
        <StepCard :processes="stepsAmount.Trânsito" :stepNumber="3" class="span-2"
                  stepName="Trânsito" @clicked="stepClick(2)"/>
        <StepCard :processes="stepsAmount.Desembaraço" :stepNumber="4" class="span-2"
                  stepName="Desembaraço" @clicked="stepClick(3)"/>
        <StepCard :processes="stepsAmount.Carregamento" :stepNumber="5" class="span-2"
                  stepName="Carregamento" @clicked="stepClick(4)"/>
        <StepCard :processes="stepsAmount.Encerramento" :stepNumber="6" class="span-2"
                  stepName="Encerramento" @clicked="stepClick(5)"/>
      </div>

      <div class="columns gap2">

        <div class="span-4">
          <h4 class="text-uppercase text-medium mb-1">Últimas atualizações</h4>
          <p class="text-light mb-2">Últimas atualizações dos processos de importação</p>

          <div class="medium-border-radius light-bordered offwhite-bg ph-1 pv-2">
            <UpdatesList :data="updates"/>
          </div>
        </div>

        <div class="span-4">
          <div class="medium-border-radius offwhite-bg pv-1 ph-2 flex ai-c jc-sb mb-2">
            <div>
              <h4 class="text-uppercase text-medium mb-1">Empresas Disponíveis</h4>
              <p class="text-light">Empresas que você tem acesso aos processos</p>
            </div>
            <!--            <div>-->
            <!--              <a class="btn light solid small only-icon" href="#">-->
            <!--                <PlusIcon size="2rem"/>-->
            <!--              </a>-->
            <!--            </div>-->
          </div>

          <div class="customers-list-home-search mb-2">
            <input
              v-if="customers.length > 0"
              id="customer-list-home-search"
              v-model="customerSearch"
              name="customer-list-home-search"
              placeholder="Pesquisar pelo nome"
              type="search"
            />
          </div>
          <ul class="customers-list-home">
            <CustomerList
              v-for="customer in filteredCustomers"
              :key="customer[0]"
              :customer="customer"
            />
          </ul>
        </div>

        <div class="span-4">
          <div class="medium-border-radius offwhite-bg p-4 mb-2 iclude-bg ">
            <p class="mb-2"><strong>{{ user.person.name }},</strong></p>

            <p class="mb-5">Pode ser que alguns <strong>Clientes ou Processos de Importação</strong>
              ainda não estejam disponíveis para você.</p>

            <p class="mb-5">Caso você não esteja visualizando determinado <strong>Processos de
              Importação e/ou Clientes</strong>, por favor, clique no botão abaixo para solicitar a
              vinculação da sua empresa no Processo de Importação.</p>

            <p class="mb-5 text-bold">
              As Empresas Disponíveis (nossos clientes) e os Processos de Importação ficam visíveis
              somente se a sua empresa estiver vinculada nos Processos de Importação.
            </p>

            <a class="btn  solid full-width extra-big" href="#"
               @click.prevent="modalRequestOpen = true">
              <span class="text-nm text-normal">SOLICITAR VÍNCULO</span>
            </a>

          </div>
        </div>

      </div>

    </div>

    <Modal :handler="modalRequestOpen" use-actions @request-close="closeRequestModal">
      <template #head>
        <h2>Solicitar vínculo com empresa</h2>
      </template>

      <!-- 47.122.001/0001-72 -->

      <template #content>
        <div class="columns gap3 ai-ge">
          <div class="span-6">
            <label for="cnpj">Consultar empresa pelo CNPJ</label>
            <input
              v-mask="'##.###.###/####-##'"
              type="text"
              name="cnpj"
              id="cnpj"
              placeholder="CNPJ do Cliente"
              v-model="companyCnpj"
            />
          </div>

          <div class="span-6">
            <button
              @click.prevent="getCompaniesByCnpj"
              type="button"
              class="btn solid primary full-width"
            >
              Consultar
            </button>
          </div>

          <div v-if="Object.keys(company).length > 0"
               class="span-12 light-bg p-2 medium-border-radius">
            <h4 class="mb-2 text-bold">Empresa encontrada</h4>
            <p>
              <span class="text-bold">Razão Social: </span>
              <span>{{ company.completeName }}</span>
            </p>
            <p>
              <span class="text-bold">Nome Fantasia: </span>
              <span>{{ company.name }}</span>
            </p>
          </div>
        </div>
      </template>

      <template #confirm-button>
        <a
          v-if="Object.keys(company).length > 0"
          class="btn solid success ml-1"
          href="#"
          @click.prevent="requestCompanyLink"
        >
          Solicitar
        </a>
      </template>
      <template #cancel-button>
        <a
          class="btn solid danger mr-1"
          href="#"
          @click.prevent="closeRequestModal"
        >
          Cancelar
        </a>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// import { PlusIcon } from '@/components/Icons';
import PageTitle from '@/components/PageTitle.vue';
import StepCard from '@/components/StepCard.vue';
import CustomerList from '@/components/CustomerList.vue';
import Modal from '@/components/Modal.vue';
import UpdatesList from '@/components/UpdatesList.vue';
import { mask } from 'vue-the-mask';
// eslint-disable-next-line import/no-cycle
import api from '../services/api';

export default {
  name: 'Home',
  directives: { mask },
  components: {
    Modal,
    // Icons
    // PlusIcon,
    // Components
    PageTitle,
    StepCard,
    CustomerList,
    UpdatesList,
  },
  data() {
    return {
      updates: [],
      customerSearch: '',
      modalRequestOpen: false,
      company: {},
      companyCnpj: '',
    };
  },
  computed: {
    ...mapState('process', ['stepsAmount']),
    ...mapState('customer', ['customers']),
    ...mapState('auth', ['user']),
    filteredCustomers() {
      if (this.customerSearch === '') {
        return this.customers;
      }
      const regExp = new RegExp(this.customerSearch, 'gi');
      return this.customers.filter((cus) => regExp.test(cus[1]));
    },
  },
  methods: {
    ...mapActions('process', ['getStepsAmount']),
    ...mapActions('customer', ['getCustomers']),
    ...mapActions('auth', ['logout']),
    ...mapActions(['toggleLoading']),
    stepClick(step) {
      this.$router.push({ name: 'Processes', params: { step } });
    },
    async getProcessUpdates() {
      try {
        const response = await api.get('/processUpdate/getUpdatesFromTheLast7Days');
        this.updates = response.data;
      } catch (err) {
        console.log(err);
      }
    },
    async getCompaniesByCnpj() {
      this.toggleLoading(true);

      try {
        const response = await api.get(`/api/public/partner/find-by-cnpj?cnpj=${this.companyCnpj}`);

        this.company = response.data.data;
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.toggleLoading(false);
      }
    },
    async requestCompanyLink() {
      this.toggleLoading(true);

      try {
        await api.post(`/api/public/partner/request-company-access/${this.company.id}`);
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.closeRequestModal();
        this.$toast.success('Vínculo solicitado com sucesso!');
        this.toggleLoading(false);
      }
    },
    closeRequestModal() {
      this.modalRequestOpen = false;
      this.company = {};
      this.companyCnpj = '';
    },
    async checkPartner() {
      try {
        const response = await api.get('/credential/check-partner');
        return response.data.data;
      } catch (e) {
        return false;
      }
    },
  },
  async created() {
    this.toggleLoading(true);

    const canUse = await this.checkPartner();

    if (canUse) {
      await this.getStepsAmount();
      await this.getCustomers();
      await this.getProcessUpdates();
    } else {
      await this.logout();
      this.$toast.error('Seu usuário não está vinculado a nenhum despachante, entre em contato com a equipe da Prime.');
    }

    this.toggleLoading(false);
  },
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    breadcrumbName: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.customers-list-home {
  max-height: 400px;
  overflow: auto;
}
</style>
