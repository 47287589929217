<template>
  <div>
    <div class="flex ai-c fgap2 pt-2 jc-sb">
      <div class="flex fgap2">
        <div class="flex ai-c" v-if="process.urgent">
          <FlagIcon class="text-danger mr-1" />
          <span class="text-bold">Urgente</span>
        </div>
        <div class="flex ai-c" v-if="process.importLicense">
          <FlagIcon class="text-danger mr-1" />
          <span class="text-bold">Licenças</span>
        </div>
        <div class="flex ai-c" v-if="process.imo">
          <FlagIcon class="text-danger mr-1" />
          <span class="text-bold">IMO</span>
        </div>
      </div>
    </div>

    <div class="modal-summary flex fw-w ai-c jc-sb fgap2 pv-2">
      <div>
        <Toggle
          name="licensa"
          :labels="{
            checked: 'Possui Licença',
            unchecked: 'Não Possui Licença',
          }"
        />
      </div>

      <div>
        <div class="flex ai-fs mb-1">
          <FileIcon class="text-light mr-1" />
          <div>
            <p class="text-light">Ref Pedido / Cliente</p>
            <p class="text-bold text-primary">{{ process.customerRef }}</p>
          </div>
        </div>
      </div>

      <div>
        <div class="flex ai-fs mb-1">
          <BuildingIcon class="text-light mr-1" />
          <div>
            <p class="text-light">Cliente</p>
            <p class="text-bold text-primary" v-if="process.customer">
              {{ process.customer.name }}
            </p>
            <p class="text-bold text-primary" v-else>-</p>
          </div>
        </div>
      </div>

      <div>
        <div class="flex ai-fs mb-1">
          <InvoiceIcon class="text-light mr-1" />
          <div>
            <p class="text-light">Invoice</p>
            <p class="text-bold text-primary">{{ process.invoice }}</p>
          </div>
        </div>
      </div>

      <div>
        <div class="flex ai-fs mb-1">
          <BoxesIcon class="text-light mr-1" />
          <div>
            <p class="text-light">Exportador</p>
            <p class="text-bold text-primary" v-if="process.exporter">
              {{ process.exporter.name }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-summary-alerts columns gap2 mb-2" v-if="missingDocs">
      <!-- <div class="span-6 alert flex fgap2 ai-c primary-bg p-1">
        <div class="alert-icon">
          <NotAllowedIcon class="text-white" />
        </div>
        <div class="alert-content flex1 text-white">
          <p>Este processo ainda não tem <strong>Autorização de Registro</strong>. Clique no botão ao lado para pedir autorização!</p>
        </div>
        <div class="alert-action">
          <a href="#" class="btn solid white text-primary">Solicitar</a>
        </div>
      </div> -->

      <div class="span-12 alert flex fgap2 ai-c danger-bg p-1">
        <div class="alert-icon">
          <TasksIcon class="text-white" />
        </div>
        <div class="alert-content flex1 text-white">
          <p>Este processo não tem todos os <strong>Documentos Originais</strong> que são necessários para despacho.</p>
        </div>
        <div class="alert-action">
          <a href="#" class="btn solid white text-primary">Solicitar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  FlagIcon,
  FileIcon,
  BuildingIcon,
  InvoiceIcon,
  BoxesIcon,
  TasksIcon,
} from '@/components/Icons';
import Toggle from '@/components/DataInput/Toggle.vue';

export default {
  name: 'ModalProcessSummary',
  components: {
    FlagIcon,
    FileIcon,
    Toggle,
    BuildingIcon,
    InvoiceIcon,
    BoxesIcon,
    TasksIcon,
  },
  props: {
    process: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      missingDocs: false,
    };
  },
  methods: {
    activeMissingDocs() {
      this.missingDocs = true;
    },
  },
  mounted() {
    window.addEventListener('missingDoc', this.activeMissingDocs);
  },
  beforeUnmount() {
    window.removeEventListener('missingDoc', this.activeMissingDocs);
  },
};
</script>

<style scoped>
</style>
