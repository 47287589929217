<template>
  <div :class="['styled-select', 'relative', useIcon == true ? 'has-icon' : '']">
    <div v-if="useIcon" class="select-icon">
      <slot class="select-icon" name="icon"></slot>
    </div>
    <ul v-if="useDate == false" class="select-list relative"
        @click.prevent="openedSelect = !openedSelect">
      <p class="title">{{ title }}</p>
      <span class="selected-option">
        {{
          Object.entries(selectedOption).length > 0 ? selectedOption.label : 'Selecione uma opção'
        }}
      </span>

      <transition mode="out-in" name="select">
        <div v-if="openedSelect" class="options">
          <li
            v-for="option in options"
            :key="getValueOption(option)"
            @click="() => {
              $emit('input', getValueOption(option));
              selectedOption = option;
            }"
          >
            {{ option.label }}
          </li>
        </div>
      </transition>
    </ul>

    <div v-else class="select-list">
      <p class="title">{{ title }}</p>
      <span class="selected-option">
        {{ value != '' ? value : 'Selecione uma data' }}
      </span>
      <DatePicker
        locale="pt-BR"
        class="invisible-date"
        valueType="DD/MM/YYYY"
        @change="$emit('input', $event)"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';

export default {
  name: 'Select',
  components: {
    DatePicker,
  },
  created() {
    const [selected] = this.options.filter((option) => option.value === this.value);
    if (selected) {
      this.selectedOption = selected;
    }
  },
  data() {
    return {
      selectedOption: {},
      openedSelect: false,
    };
  },
  methods: {
    getValueOption(option) {
      if (this.valueIsObject) {
        return option;
      }

      return option.value ? option.value : option.attribute;
    },
  },
  mounted() {
    document.addEventListener('click', (event) => {
      if (!event.target.closest('.styled-select')) {
        this.openedSelect = false;
      }
    });
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    useIcon: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      default: '',
    },
    useDate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.styled-select {
  z-index: 9999;
}

.styled-select .select-icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
}

.styled-select .select-list {
  border: var(--small-border-width) solid var(--primary-color);
  height: 50px;
  border-radius: var(--round-border-radius);
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0.3rem 3rem 0.3rem 1.5rem;
  background: url("data:image/svg+xml;charset=utf8, %3Csvg width='24px' height='24px' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.75 9.5L12.75 15.5L18.75 9.5' fill='currentColor'/%3E%3C/svg%3E") calc(100% - 1rem) center / 24px no-repeat;
}

.styled-select.has-icon .select-list {
  padding-left: 50px;
}

.styled-select .select-list .title {
  font-weight: 700;
  font-size: 0.8rem;
  color: var(--primary-color);
}

.styled-select .select-list .selected-option {
  font-size: 0.6rem;
}

.styled-select .options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--white-color);
  border-radius: var(--medium-border-radius);
  border: var(--input-border);
  overflow: hidden;
  max-height: 350px;
  overflow: auto;
}

.styled-select .options li {
  font-size: 0.8rem;
  padding: 1rem 1rem;
  transition: var(--all-transition);
}

.styled-select .options li:hover {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.select-enter-active,
.select-leave-active {
  transition: var(--all-transition);
}

.select-enter,
.select-leave-to {
  opacity: 0;
  transform: translate3d(0, -30px, 0);
}

.select-enter-to,
.select-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: var(--all-transition);
}

.invisible-date {
  position: absolute;
  inset: 0;
  opacity: 0 !important;;
}
</style>
