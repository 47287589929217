<template>
  <transition name="modal">
    <div
      v-if="handler"
      class="modal"
      :style="{
        backgroundColor: `rgba(30, 30, 30, ${backgroundOpacity})`
      }"
      @click.self.prevent="$emit('request-close', true)"
    >

      <div :class="['modal-content-wrapper', fullWidth && 'full']" :style="{
        maxWidth: `${maxWidth}vw`
      }">

        <div class="modal-head relative">
          <slot name="head"></slot>
          <div v-if="closeButton" class="close-modal" @click.prevent="$emit('request-close', true)">
            <CloseIcon class="text-primary" size="1rem"/>
          </div>
        </div>

        <div class="modal-content">
          <slot name="content"></slot>
        </div>

        <div v-if="useActions" class="modal-actions flex fw-w ai-c jc-c text-center">
          <slot name="cancel-button"></slot>
          <slot name="confirm-button"></slot>
        </div>

      </div>

    </div>
  </transition>
</template>

<script>
import { CloseIcon } from '@/components/Icons';

export default {
  name: 'Modal',
  components: {
    CloseIcon,
  },
  props: {
    closeButton: {
      type: Boolean,
      default: true,
    },
    useActions: {
      type: Boolean,
      default: false,
    },
    backgroundOpacity: {
      type: Number,
      default: 0.8,
    },
    handler: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 85,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>

.modal {
  position: fixed;
  inset: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content-wrapper {
  min-width: 40vw;
  max-height: 85vh;
  background-color: var(--white-color);
  border-radius: var(--medium-border-radius);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-top: 8px solid var(--primary-color);
}

.modal-content-wrapper.full {
  width: 100%;
}

.modal-head {
  font-size: 1.1rem;
  font-weight: 700;
  padding: 1rem 1.5rem;
}

.modal-content {
  padding: 1.5rem;
  font-size: 1.1rem;
  color: var(--text-color);
  overflow: auto;
}

.modal-actions {
  padding: 1rem 1.5rem;
}

.close-modal {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.modal-enter-active,
.modal-leave-active {
  transition: var(--all-transition);
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-to,
.modal-leave {
  opacity: 1;
}

@media screen and (max-width: 1020px) {
  .modal .modal-content-wrapper {
    max-width: 80vw !important;
  }
}
</style>
