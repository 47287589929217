<template>
  <div>
    <PageTitle breadcrumbName="Meus Dados" class="mb-2" pageTitle="Meus Dados"/>
    <div class="card flex fd-c fgap2">
      <div>
        <h3 class="mb-1">MEUS DADOS</h3>
        <p class="text-light">Configurações dos seus dados no portal</p>
      </div>

      <div class="columns">

        <form class="columns gap2 ac-gs span-8 pr-3 user-form" @submit.prevent="updateUserData">
          <div class="span-6">
            <Input v-model="userData.name" className="text-bold" label="Nome Completo"
                   name="name"
                   placeholder="Nome Completo"/>
          </div>

          <div class="span-6">
            <Input v-model="userData.email" className="text-bold" label="Email" name="email"
                   placeholder="Email"/>
          </div>

          <div class="span-12">
            <Toggle v-model="changePassword" :labels="{
              checked: 'Alterar Senha',
              unchecked: 'Alterar Senha'
            }" name="sit-siscarga"/>
          </div>

          <transition name="pages">
            <div v-if="changePassword" class="span-6">
              <Input
                v-model="userData.password"
                :hint="true"
                className="text-bold"
                hintMessage="Sua senha deverá conter pelo menos um caractere especial ( ex.: !#$%ˆ&*()_? ) e pelo menos um dígito."
                label="Nova Senha"
                name="password"
                placeholder="Nova Senha"
                type="password"
              />
            </div>
          </transition>

          <transition name="pages">
            <div v-if="changePassword" class="span-6">
              <Input
                v-model="userData.repeatedPassword"
                :hint="true"
                className="text-bold"
                hintMessage="Repita a senha anterior"
                label="Repita a Nova Senha" name="rePassword" placeholder="Repita a Nova Senha"
                type="password"
              />
            </div>
          </transition>

          <div class="span-12">
            <button class="btn solid primary full-width">ATUALIZAR MEUS DADOS</button>
          </div>
        </form>

        <div class="span-4 pl-3">
          <div class="medium-border-radius offwhite-bg pv-1 ph-2 flex ai-c jc-sb mb-2">
            <div>
              <h4 class="text-uppercase text-medium mb-1">Empresas Vinculadas</h4>
              <p class="text-light">Empresas que você tem acesso</p>
            </div>
          </div>

          <ul class="company-list">
            <CompanyList v-for="customer in customBrokers" :key="customer.id"
                         :customer="customer"/>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';
import PageTitle from '@/components/PageTitle.vue';

import Input from '@/components/DataInput/Input.vue';
import Toggle from '@/components/DataInput/Toggle.vue';

import CompanyList from '@/components/CompanyList.vue';

export default {
  name: 'MyData',
  components: {
    // Components
    PageTitle,
    Input,
    Toggle,
    CompanyList,
  },
  data() {
    return {
      userData: {},
      changePassword: false,
      oldEmail: '',
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    customBrokers() {
      if (this.userData.companies) {
        return this.userData.companies.filter((c) => c.customBroker === true);
      }
      return [];
    },
  },
  methods: {
    ...mapActions(['toggleLoading']),
    ...mapActions('auth', ['logout']),
    async getUserData() {
      await this.toggleLoading(true);

      const userResponse = await api.get('/credential');
      this.userData = userResponse.data.data.person;
      this.oldEmail = userResponse.data.data.person.email;

      await this.toggleLoading(false);
    },
    async updateUserData() {
      try {
        const dataProfile = {
          id: this.userData.id,
          name: this.userData.name,
          email1: this.userData.email,
          password: this.changePassword ? this.userData.password : null,
          repeatedPassword: this.changePassword ? this.userData.repeatedPassword : null,
          followUP: false,
          followUPAnalytical: false,
          followUPSynthetic: false,
          followUPSyntheticWeekday: null,
        };

        if (this.changePassword) {
          if (
            (dataProfile.password !== 'undefined' && dataProfile.password !== '')
            && (dataProfile.repeatedPassword !== 'undefined' && dataProfile.repeatedPassword !== '')
          ) {
            if (dataProfile.password !== dataProfile.repeatedPassword) {
              this.$toast.error('As senhas devem ser iguais.');
            } else {
              const response = await api.patch('/credential/updateProfile', dataProfile);

              if (response.data.code === 'SUCCESS') {
                this.$toast.success('Sua senha foi alterada com sucesso, você redirecionado para a página de login', {
                  position: 'top-center',
                });
              }

              setTimeout(() => {
                this.logout();
              }, 3000);
            }
          } else {
            this.$toast.error('A senha não pode ser vazia.');
          }
        } else {
          const response = await api.patch('/credential/updateProfile', dataProfile);

          const emailAlterado = this.oldEmail !== dataProfile.email1;
          const message = emailAlterado
            ? 'Seu email foi alterado com sucesso, você será redirecionado para a página de login'
            : 'Dadaos alterados com sucesso';

          if (response.data.code === 'SUCCESS') {
            this.$toast.success(message, {
              position: 'top-center',
            });
          }

          if (emailAlterado) {
            setTimeout(() => {
              this.logout();
            }, 3000);
          }
        }
      } catch (err) {
        this.$toast.error('Sua Senha deve conter pelo menos um caractere especial ex.: _()@!?$%*#&.', {
          position: 'top-center',
        });
      }
    },
  },
  mounted() {
    this.toggleLoading(true);
    this.getUserData();
    this.toggleLoading(false);
  },
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    breadcrumbName: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.user-form {
  border-right: var(--input-border);
}

.company-list {
  max-height: 450px;
  overflow: auto;
}
</style>
