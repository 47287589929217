<template>
  <div>
    <div :class="[
     'toggle-sidebar pointer',
     !hideSidebar ? 'active' : ''
    ]" @click="toggleHideSidebar(!hideSidebar)" >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
    </div>

    <AgGridTable
      :columns="tableColumns"
      :data="processes.length > 0 ? processes : []"
      enableSave
      @ref-click="openProcessById"
      @step-change="changeProcessStep"
      :etapa="Number(filtroEtapa)"
    />

    <ModalProcess
      v-if="process !== null"
      :handler="modalProcessOpen"
      @request-close="closeModal"
      :data="modalData"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ModalProcess from '@/components/ModalProcess.vue';

import AgGridTable from '../components/AgGridTable.vue';

export default {
  name: 'Home',
  components: {
    ModalProcess,
    AgGridTable,
  },
  data() {
    return {
      modalProcessOpen: false,
      drawerOpen: true,
      filtroEtapa: 2,
      // filtroCampo: '',
      dataInicio: '',
      dataFim: '',
      // opcoesFiltroCampo: [],
      modalData: {
        containers: [],
        documents: [],
      },
    };
  },
  methods: {
    ...mapActions('process', [
      'getStepsAmount',
      'getSteps',
      'getProcesses',
      'getProcess',
      'getProcessById',
      'clearProcess',
      'clearSingleProcess',
      'getProcessContainers',
      'getProcessDocuments',
      'updateTableColumns',
      'syncTableColumns',
    ]),
    ...mapActions(['toggleLoading', 'toggleHideHeader', 'toggleHideSidebar']),
    async openProcessById(process) {
      this.toggleLoading(true);

      await this.getProcessById(process);
      this.modalData.containers = await this.getProcessContainers(
        this.process.id,
      );
      this.modalData.documents = await this.getProcessDocuments(
        this.process.id,
      );
      this.modalProcessOpen = true;

      this.toggleLoading(false);
    },
    async openProcess(process) {
      this.toggleLoading(true);

      await this.getProcess(process);
      this.modalData.containers = await this.getProcessContainers(
        this.process.id,
      );
      this.modalData.documents = await this.getProcessDocuments(
        this.process.id,
      );
      this.modalProcessOpen = true;

      this.toggleLoading(false);
    },
    closeModal() {
      this.clearSingleProcess();
      this.modalProcessOpen = false;
      this.modalData = {
        containers: [],
        documents: [],
      };
    },
    async generate() {
      await this.toggleLoading(true);
      await this.syncTableColumns(this.stepColumns[String(this.filtroEtapa)]);
      await this.getProcesses({ stepId: this.filtroEtapa });
      await this.toggleLoading(false);
    },
    async changeProcessStep(step) {
      await this.toggleLoading(true);
      this.filtroEtapa = Number(step);
      await this.syncTableColumns(this.stepColumns[step]);
      await this.getProcesses({ stepId: step });
      await this.toggleLoading(false);
    },
  },
  async mounted() {
    if (this.$route.params.step) {
      this.filtroEtapa = this.$route.params.step;
    }
    this.toggleLoading(true);
    this.toggleHideHeader(true);
    this.toggleHideSidebar(true);
    await this.syncTableColumns(this.stepColumns[String(this.filtroEtapa)]);
    await this.getSteps();
    // await this.getProcesses({ stepId: 8 });
    if (!this.generalFilter) {
      await this.getProcesses({ stepId: this.filtroEtapa });
    }
    this.toggleLoading(false);
  },
  computed: {
    ...mapState('process', ['steps', 'processes', 'process', 'tableColumns', 'stepColumns', 'generalFilter', 'openedProcesses', 'socket']),
    ...mapState(['hideSidebar']),
    generateSteps() {
      return this.steps.map((step) => ({ label: step.step, value: step.id }));
    },
  },
  beforeDestroy() {
    this.clearProcess();
    this.toggleHideHeader(false);
    this.toggleHideSidebar(false);
  },
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    breadcrumbName: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
  .toggle-sidebar {
    position: fixed;
    top: 10px;
    left: 0;
    z-index: 999;
    width: 35px;
    height: 35px;
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 0.5rem;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .toggle-sidebar.active {
    left: 80px;
  }

  .step-list {
    background-color: white;
    border-radius: var(--round-border-radius);
    border: var(--input-border);
    overflow: hidden;
  }

  .step-list li {
    display: block;
  }

  .step-list li:not(:last-of-type) {
    border-right: var(--input-border);
  }

  .step-btn {
    font-weight: 300;
    display: block;
    padding: 0.75rem 1rem;
  }

  .step-btn.active {
    font-weight: 400;
    background-color: var(--primary-color);
    color: var(--white-color);
  }
</style>
