<template>
  <vc-date-picker
    locale="pt-BR"
    :value="value"
    @input="$emit('input', $event)"
    :model-config="{
      type: 'string',
      mask: 'iso',
      timeAdjust: '00:00:00',
    }"
    :style="`${disabled ? 'pointer-events: none;' : ''}`"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <input
        type="text"
        :class="['bg-white border px-2 py-1', fullHeight && 'input-full-height']"
        :value="inputValue"
        v-on="inputEvents"
        :placeholder="placeholder"
        :disabled="disabled"
      />
    </template>
  </vc-date-picker>
</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    fullHeight: Boolean,
  },
};
</script>

<style scoped>
  body input {
    font-weight: bold;
    height: 40px;
  }

  body input.input-full-height {
    height: 3.125rem;
  }
</style>
