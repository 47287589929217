<template>
  <div class="card">
    <div class="flex fw-w fgap2 ai-c jc-sb mb-2">
      <form class="header-search relative" @submit.prevent>
        <input id="filter-text-box" ref="search" class="rounded small header-search-input" placeholder="Pesquisar..."
          type="text" v-on:input="onFilterTextBoxChanged()">
        <button type="button">
          <SearchIcon />
        </button>
      </form>

      <div class="flex ai-c jc-sb gap2">
        <ul class="step-list flex ai-c fw-w">
          <li v-for="step in generateSteps" :key="step.value">
            <a :class="['step-btn text-sm', etapa === step.value ? 'active' : '']" href="#"
              @click.prevent="$emit('step-change', step.value)">{{ step.label }}</a>
          </li>
        </ul>
        <p class="text-sm text-primary">{{ processes.length }} processos</p>
      </div>

      <div class="flex ai-c fgap1">
        <download-excel :data="data" :fields="exportColumns" :name="new Date() | moment('DD/MM/YYYY')" type="xls">
          <button class="btn solid primary rounded small flex ai-c jc-c useHint" type="button">
            <svg height="24" viewBox="0 0 384 512" width="24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M365.3 93.38l-74.63-74.64C278.6 6.742 262.3 0 245.4 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.34 28.65 64 64 64H320c35.2 0 64-28.8 64-64V138.6C384 121.7 377.3 105.4 365.3 93.38zM336 448c0 8.836-7.164 16-16 16H64.02c-8.838 0-16-7.164-16-16L48 64.13c0-8.836 7.164-16 16-16h160L224 128c0 17.67 14.33 32 32 32h79.1V448zM229.1 233.3L192 280.9L154.9 233.3C146.8 222.8 131.8 220.9 121.3 229.1C110.8 237.2 108.9 252.3 117.1 262.8L161.6 320l-44.53 57.25c-8.156 10.47-6.25 25.56 4.188 33.69C125.7 414.3 130.8 416 135.1 416c7.156 0 14.25-3.188 18.97-9.25L192 359.1l37.06 47.65C233.8 412.8 240.9 416 248 416c5.125 0 10.31-1.656 14.72-5.062c10.44-8.125 12.34-23.22 4.188-33.69L222.4 320l44.53-57.25c8.156-10.47 6.25-25.56-4.188-33.69C252.2 220.9 237.2 222.8 229.1 233.3z"
                fill="currentColor" />
            </svg>
            <div class="hint">Exportar relatório</div>
          </button>
        </download-excel>
        <button class="btn solid primary rounded small flex ai-c jc-c useHint" type="button"
          @click="drawerColumnsOpen = true">
          <svg class="feather feather-bookmark" fill="none" height="24" stroke="currentColor" stroke-linecap="round"
            stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
          </svg>
          <div class="hint">Modelos de Colunas</div>
        </button>
        <button class="btn solid primary rounded small flex ai-c jc-c useHint" type="button" @click="drawerOpen = true">
          <svg class="feather feather-list" fill="none" height="24" stroke="currentColor" stroke-linecap="round"
            stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <line x1="8" x2="21" y1="6" y2="6"></line>
            <line x1="8" x2="21" y1="12" y2="12"></line>
            <line x1="8" x2="21" y1="18" y2="18"></line>
            <line x1="3" x2="3.01" y1="6" y2="6"></line>
            <line x1="3" x2="3.01" y1="12" y2="12"></line>
            <line x1="3" x2="3.01" y1="18" y2="18"></line>
          </svg>
          <div class="hint">Organizar Colunas</div>
        </button>
        <button class="btn solid primary rounded small flex ai-c jc-c useHint" @click="drawerLegendOpen = true">
          <svg height="24" viewBox="0 0 576 512" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M512 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h448c35.35 0 64-28.65 64-64V96C576 60.65 547.3 32 512 32zM528 416c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V96c0-8.822 7.178-16 16-16h448c8.822 0 16 7.178 16 16V416zM236.5 222.1c9.375 9.375 24.56 9.375 33.94 0c9.375-9.375 9.375-24.56 0-33.94c-37.44-37.44-98.31-37.44-135.7 0C116.5 206.2 106.5 230.4 106.5 256s9.1 49.75 28.12 67.88c18.72 18.72 43.28 28.08 67.87 28.08s49.16-9.359 67.87-28.08c9.375-9.375 9.375-24.56 0-33.94c-9.375-9.375-24.56-9.375-33.94 0c-18.69 18.72-49.19 18.72-67.87 0C159.5 280.9 154.5 268.8 154.5 256s5-24.88 14.06-33.94C187.3 203.3 217.8 203.3 236.5 222.1zM428.5 222.1c9.375 9.375 24.56 9.375 33.94 0c9.375-9.375 9.375-24.56 0-33.94c-37.44-37.44-98.31-37.44-135.7 0C308.5 206.2 298.5 230.4 298.5 256s9.1 49.75 28.12 67.88c18.72 18.72 43.28 28.08 67.87 28.08s49.16-9.359 67.87-28.08c9.375-9.375 9.375-24.56 0-33.94c-9.375-9.375-24.56-9.375-33.94 0c-18.69 18.72-49.19 18.72-67.87 0C351.5 280.9 346.5 268.8 346.5 256s5-24.88 14.06-33.94C379.3 203.3 409.8 203.3 428.5 222.1z"
              fill="currentColor" />
          </svg>
          <div class="hint">Legendas</div>
        </button>
      </div>
    </div>

    <ag-grid-vue :columnDefs="columns" :localeText="tableLang" :rowClassRules="rowClassRules" :rowData="data"
      class="ag-theme-alpine" @cell-value-changed="changeCell" @grid-ready="onGridReady" @cell-clicked="refClicked">
    </ag-grid-vue>

    <DrawerModal :handler="drawerOpen" title="Gerenciar colunas" @request-close="drawerOpen = false">
      <template #content>
        <div class="flex fgap2">
          <div class="flex1">
            <h5 class="mb-2">Colunas ativas</h5>
            <input v-model="filterShowColumns" class="mb-2" name="hided-columns" placeholder="Pesquisar" type="text">
            <ul class="column-list">
              <draggable v-model="myList" :move="checkMove">
                <template v-for="column in showingColumns">
                  <li :key="column.field" class="flex ai-c jc-sb">
                    <label class="flex ai-c fgap1 text-sm">
                      <input :checked="!column.hide" :name="column.headerName" :value="column.headerName"
                        type="checkbox" @input="clicked">
                      {{ column.headerName }}
                    </label>
                    <div v-if="!blockedColumns.find((item) => item === column.field)" class="p-1 pointer">
                      <svg height="12px" viewBox="0 0 448 512" width="12px" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M416 288C433.7 288 448 302.3 448 320C448 337.7 433.7 352 416 352H32C14.33 352 0 337.7 0 320C0 302.3 14.33 288 32 288H416zM416 160C433.7 160 448 174.3 448 192C448 209.7 433.7 224 416 224H32C14.33 224 0 209.7 0 192C0 174.3 14.33 160 32 160H416z" />
                      </svg>
                    </div>
                  </li>
                </template>
                <!-- <div v-for="element in tableColumns" :key="element.headerName">{{element.headerName}}</div> -->
              </draggable>
            </ul>
          </div>
          <div class="flex1">
            <h5 class="mb-2">Colunas inativas</h5>
            <!-- {{filterHideColumns}} -->
            <input v-model="filterHideColumns" class="mb-2" name="hided-columns" placeholder="Pesquisar" type="text">
            <ul class="column-list">
              <template v-for="column in hidedColumns">
                <li :key="column.field">
                  <label class="flex ai-c fgap1 text-sm">
                    <input :checked="!column.hide" :name="column.headerName" :value="column.headerName" type="checkbox"
                      @input="clicked">
                    {{ column.headerName }}
                  </label>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="columns gap2 ai-gc save-columns">
          <div class="span-6 p-2">
            <label for="nome-modelo">Você deseja salvar as colunas selecionadas?</label>
            <p class="text-sm">Caso sim, digite o nome ao lado e salve.</p>
          </div>
          <div class="span-6 save-cont p-2">
            <input v-model="nomeModelo" class="rounded" name="nome-modelo" placeholder="Digite o nome do modelo"
              type="text">
            <button class="btn solid primary rounded small" @click.prevent="saveColumns">
              <svg class="feather feather-save" fill="none" height="18" stroke="currentColor" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="18"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                <polyline points="17 21 17 13 7 13 7 21"></polyline>
                <polyline points="7 3 7 8 15 8"></polyline>
              </svg>
            </button>
          </div>
        </div>
      </template>
    </DrawerModal>

    <DrawerModal :handler="drawerFilterOpen" title="Filtrar Processos" @request-close="drawerFilterOpen = false">
      <template #content>
        <div class="columns gap2">
          <div class="span-12" style="z-index: 9999999;">
            <Select v-model="filtroCampo" :options="opcoesFiltroCampo" :useIcon="true" title="Campo">
              <template #icon>
                <CalendarIcon />
              </template>
            </Select>
          </div>

          <div class="span-6">
            <Select v-model="dataInicio" :useDate="true" :useIcon="true" title="Data Inicial">
              <template #icon>
                <CalendarIcon />
              </template>
            </Select>
          </div>

          <div class="span-6">
            <Select v-model="dataFim" :useDate="true" :useIcon="true" title="Data Final">
              <template #icon>
                <CalendarIcon />
              </template>
            </Select>
          </div>
        </div>
      </template>
      <template #footer>
        <div>
          <button class="btn solid primary full-width rounded" @click.prevent="">
            GERAR
          </button>
        </div>
      </template>
    </DrawerModal>

    <DrawerModal :handler="drawerColumnsOpen" title="Carregar Colunas" @request-close="drawerColumnsOpen = false">
      <template #content>
        <ul v-if="columnsModels.length > 0" class="column-list">
          <li v-for="model in columnsModels" :key="model.id">
            <label class="flex ai-c jc-sb">
              <a href="#">{{ model.name }}</a>
              <div class="flex ai-c fgap2">
                <a class="btn solid success small" href="#" @click.prevent="loadColumnModel(model.id)">
                  <svg class="feather feather-download" fill="none" height="24" stroke="currentColor"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="7 10 12 15 17 10"></polyline>
                    <line x1="12" x2="12" y1="15" y2="3"></line>
                  </svg>
                </a>
                <a class="btn solid danger small" href="#" @click.prevent="removeColumnModel(model.id)">
                  <svg class="feather feather-trash" fill="none" height="24" stroke="currentColor"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
                    xmlns="http://www.w3.org/2000/svg">
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  </svg>
                </a>
              </div>
            </label>
          </li>
        </ul>
        <h3 v-else class="text-bold">Nenhum modelo salvo</h3>
      </template>
      <template #footer>
        Selecione o seu modelo de colunas
      </template>
    </DrawerModal>

    <DrawerModal :handler="drawerLegendOpen" title="Legendas" @request-close="drawerLegendOpen = false">
      <template #content>
        <Legends />
      </template>
      <template #footer>
        Legendas da tabela
      </template>
    </DrawerModal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import draggable from 'vuedraggable';
import moment from 'moment';
import Select from '@/components/DataInput/Select.vue';
import AG_GRID_LOCALE_PT from '@/utils/data-grid-local';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { CalendarIcon, SearchIcon } from '@/components/Icons';
import Legends from '@/components/Legends.vue';

import DrawerModal from '@/components/DrawerModal.vue';

import api from '@/services/api';

export default {
  name: 'AgGridTable',
  components: {
    CalendarIcon,
    DrawerModal,
    AgGridVue,
    draggable,
    Select,
    SearchIcon,
    Legends,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    enableSave: {
      type: Boolean,
      default: false,
    },
    etapa: {
      type: Number,
      default: 8,
    },
  },
  data() {
    return {
      nomeModelo: '',
      modeloColunas: null,
      gridApi: null,
      columnState: null,
      tableLang: AG_GRID_LOCALE_PT,
      drawerOpen: false,
      drawerFilterOpen: false,
      drawerColumnsOpen: false,
      drawerLegendOpen: false,
      rowClassRules: {
        'danger-bg': (params) => params.data.urgent,
      },
      // Filtros
      filtroCampo: '',
      dataInicio: '',
      dataFim: '',
      filterHideColumns: '',
      filterShowColumns: '',
      columnsModels: [],
      blockedColumns: [
        'customer.name',
        'identification',
        'step.alias',
        'status',
        'customerRef',
      ],
      refreshTimeout: null,
    };
  },
  computed: {
    ...mapState('process', ['processes', 'tableColumns', 'steps', 'openedProcesses']),
    myList: {
      get() {
        return this.tableColumns;
      },
      set(value) {
        this.sortTableColumns(value);
      },
    },
    generateSteps() {
      return this.steps.map((step) => ({ label: step.step, value: step.id }));
    },
    hidedColumns() {
      if (this.filterHideColumns !== '') {
        const columns = this.myList.filter((col) => col.hide === true);
        return columns.filter((item) => String(item.headerName).match(new RegExp(this.filterHideColumns, 'gi')));
      }
      return this.myList.filter((col) => col.hide === true);
    },
    showingColumns() {
      if (this.filterShowColumns !== '') {
        const columns = this.myList.filter((col) => col.hide === false);
        return columns.filter((item) => String(item.headerName).match(new RegExp(this.filterShowColumns, 'gi')));
      }
      return this.myList.filter((col) => col.hide === false);
    },
    opcoesFiltroCampo() {
      return this.myList.filter((col) => col.filter === 'agDateColumnFilter').map((col) => ({
        label: col.headerName,
        value: col.field,
      }));
    },
    exportColumns() {
      const cols = {};
      this.showingColumns.map((col) => {
        if (col.filter === 'agDateColumnFilter') {
          return Object.assign(cols, {
            [col.headerName]: {
              field: col.field,
              callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
            },
          });
        }
        if (col.field === 'datesIsThereDTCDTATransfer') {
          return Object.assign(cols, {
            [col.headerName]: {
              field: col.field,
              callback: (value) => {
                if (value) {
                  if (value === true || value === 'true') {
                    return 'Sim';
                  }
                  return 'Não';
                }
                return 'N/i';
              },
            },
          });
        }
        return Object.assign(cols, {
          [col.headerName]: col.field,
        });
      });
      return cols;
    },
  },
  methods: {
    ...mapActions('process', [
      'updateTableColumns',
      'sortTableColumns',
      'getSteps',
      'syncTableColumns',
      'updateProcesses',
    ]),
    ...mapActions(['toggleLoading']),
    clicked(e) {
      this.updateTableColumns(e.target.value);
    },
    async changeCell(cell) {
      try {
        await api.patch(`/api/public/partner/processes/${cell.data.identification}`, cell.data);
      } catch (err) {
        console.log(err);
      }
      // Endpoint -> /api/public/partner/processes/4
    },
    onGridReady(params) {
      this.gridApi = params.api;
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(
        this.$refs.search.value,
      );
    },
    // saveColumnsState() {
    //   this.columnState = this.gridApi.getColumnDefs();
    // },
    // loadColumnState() {
    //   this.gridApi.setColumnDefs(this.columnState);
    // },
    refClicked(cell) {
      if (cell.colDef.field === 'identification') {
        this.$emit('ref-click', cell.data.id);
      }
    },
    async getColumnModels() {
      const response = await api.get('/desk/states');
      this.columnsModels = response.data.data;
    },
    async saveColumns() {
      await this.toggleLoading(true);

      const activeColumns = [];
      const stepObject = [];

      this.showingColumns.map((column) => activeColumns.push(column.field));
      const etapaSelecionada = this.generateSteps.filter((item) => item.value === this.etapa);

      stepObject.push(etapaSelecionada.value);

      const response = await api.post('/desk/states', {
        name: this.nomeModelo,
        state: activeColumns,
      });

      if (response) {
        await this.getColumnModels();
        this.nomeModelo = '';
        this.drawerOpen = false;
      }

      await this.toggleLoading(false);
    },
    async loadColumnModel(id) {
      await this.toggleLoading(true);

      const response = await api.get(`/desk/states/${id}`);

      await this.syncTableColumns(response.data.data.state);

      this.drawerColumnsOpen = false;

      await this.toggleLoading(false);
    },
    async removeColumnModel(id) {
      await this.toggleLoading(true);

      const response = await api.delete(`/desk/states/${id}`);

      if (response) {
        await this.getColumnModels();
      }

      await this.toggleLoading(false);
    },
    checkMove(e) {
      if (this.blockedColumns.find((item) => item === e.draggedContext.element.field)) {
        return false;
      }
      return true;
    },
    refreshCells() {
      // clearTimeout(this.refreshTimeout);
      if (this.data.length > 0) {
        // this.refreshTimeout = setTimeout(() => {
        this.gridApi.refreshCells({
          force: true,
        });
        // }, 200);
      }
    },
  },
  async mounted() {
    await this.getColumnModels();
    await this.getSteps();
  },
  watch: {
    processes() {
      this.refreshCells();
    },
    openedProcesses() {
      this.refreshCells();
    },
  },
};
</script>

<style scoped>
.ag-theme-alpine {
  width: 100%;
  height: calc(100vh - 140px);
}

.column-list {
  border: var(--input-border);
  border-bottom: 0;
}

.column-list li {
  border-bottom: var(--input-border);
}

.column-list label {
  margin-bottom: 0;
  padding: 0.5rem;
}

.step-list {
  background-color: white;
  border-radius: var(--round-border-radius);
  border: var(--input-border);
  overflow: hidden;
}

.step-list li {
  display: block;
}

.step-list li:not(:last-of-type) {
  border-right: var(--input-border);
}

.step-btn {
  font-weight: 300;
  display: block;
  padding: 0.65rem 1rem;
}

.step-btn.active {
  font-weight: 400;
  background-color: var(--primary-color);
  color: var(--white-color);
}

.header-search button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 3rem;
  text-align: center;
}

.header-search-input {
  padding-left: 3rem !important;
}

.save-cont {
  position: relative;
}

.save-columns label {
  font-size: 0.8rem !important;
  margin-bottom: 2px;
}

.save-cont input {
  height: 50px !important;
}

.save-cont button {
  position: absolute;
  top: 0;
  right: 25px;
  bottom: 0;
  margin: auto 0;
  height: 40px;
}
</style>
