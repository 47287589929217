<template>
  <div v-if="activeTab == 1">
    <div class="columns gap2">
      <div class="span-3">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <BuildingIcon class="mr-1"/>
            Cliente
          </div>
        </div>
        <input v-model="process.customer.name" disabled placeholder='Não Informado' type="text"/>
      </div>

      <div class="span-3">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <BoxesIcon class="mr-1"/>
            Exportador
          </div>
        </div>
        <input v-model="process.exporter.name" disabled placeholder='Não Informado' type="text"/>
      </div>

      <div class="span-3">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <IndustryIcon class="mr-1"/>
            Fabricante / Produtor
          </div>
        </div>
        <input v-model="process.manufacturer.name" disabled placeholder='Não Informado'
               type="text"/>
      </div>

      <div class="span-3">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <IdCardIcon class="mr-1"/>
            Despachante Aduaneiro
          </div>
        </div>
        <input v-model="process.customBroker.name" disabled placeholder='Não Informado'
               type="text"/>
      </div>

      <div class="span-2">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <InvoiceIcon class="mr-1"/>
            Invoice
          </div>
        </div>
        <input v-model="process.invoice" disabled placeholder='Não Informado' type="text"/>
      </div>

      <div class="span-2">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <CalendarAltIcon class="mr-1"/>
            Data Invoice
          </div>
        </div>
        <input :value="formatData(process.invoiceDate)" disabled placeholder='Não Informado'
               type="text"/>
      </div>

      <div class="span-2">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <FileIcon class="mr-1"/>
            Ref. Pedido / Cliente
          </div>
        </div>
        <input v-model="process.customerRef" disabled placeholder='Não Informado' type="text"/>
      </div>

      <div class="span-2">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <FileIcon class="mr-1"/>
            Outras Ref.
          </div>
        </div>
        <input v-model="process.anotherRef" disabled placeholder='Não Informado' type="text"/>
      </div>

      <div class="span-2">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <FileIcon class="mr-1"/>
            Ref. Despachante
          </div>
        </div>
        <input v-model="process.customBrokerRef" :disabled="disableClick"
               type="text" @input="() => inputUpdate('customBrokerRef')"/>
      </div>

      <div class="span-2">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <MapPinIcon class="mr-1"/>
            Local de Embarque
          </div>
        </div>
        <input v-model="process.placeOfLoading" disabled placeholder='Não Informado' type="text"/>
      </div>
    </div>

    <hr class="mv-2"/>

    <div class="columns gap2">
      <div class="span-6 columns gap2">
        <div class="span-3 p-1 medium-border-radius flex fd-c" style="background-color: #FBF7EE;">
          <h6 class="text-bold text-center mb-2">PREVISTAS</h6>
          <div class="mb-2">
            <div class="flex ai-c jc-sb mb-1">
              <div class="flex ai-c text-light">
                <CalendarAltIcon class="mr-1"/>
                Prontidão
              </div>
            </div>
            <DatePicker v-model="process.datesEstimatedGoodsReadinesDate" disabled
                        placeholder='Não Informado'/>
          </div>

          <div class="mb-2">
            <div class="flex ai-c jc-sb mb-1">
              <div class="flex ai-c text-light">
                <CalendarAltIcon class="mr-1"/>
                ETD Inicial
              </div>
            </div>
            <DatePicker v-model="process.initialDatesETD" disabled placeholder='Não Informado'/>
          </div>

          <div class="mb-2">
            <div class="flex ai-c jc-sb mb-1">
              <div class="flex ai-c text-light">
                <CalendarAltIcon class="mr-1"/>
                ETD
              </div>
            </div>
            <DatePicker v-model="process.datesETD" disabled placeholder='Não Informado'/>
          </div>

          <div class="mb-2">
            <div class="flex ai-c jc-sb mb-1">
              <div class="flex ai-c text-light">
                <CalendarAltIcon class="mr-1"/>
                ETA
              </div>
            </div>
            <DatePicker v-model="process.datesETA" disabled placeholder='Não Informado'/>
          </div>

          <div>
            <div class="flex ai-c jc-sb mb-1">
              <div class="flex ai-c text-light">
                <CalendarAltIcon class="mr-1"/>
                Entr. Cliente
              </div>
            </div>
            <DatePicker v-model="process.datesEstimatedCustomerDeliverDate" disabled
                        placeholder='Não Informado'/>
          </div>
        </div>

        <div class="span-6 p-1 medium-border-radius" style="background-color: #ECF6EB;">
          <h6 class="text-bold text-center mb-2">CONFIRMADAS</h6>
          <div class="columns gap2">
            <div class="span-6">
              <div class="mb-2">
                <div class="flex ai-c jc-sb mb-1">
                  <div class="flex ai-c text-light">
                    <CalendarAltIcon class="mr-1"/>
                    Prontidão
                  </div>
                </div>
                <DatePicker v-model="process.datesGoodsReadinesDate" disabled
                            placeholder='Não Informado'/>
              </div>

              <div class="mb-2">
                <div class="flex ai-c jc-sb mb-1">
                  <div class="flex ai-c text-light">
                    <CalendarAltIcon class="mr-1"/>
                    Atracação
                  </div>
                </div>
                <DatePicker v-model="process.mooringDate" disabled placeholder='Não Informado'/>
              </div>

              <div class="mb-2">
                <div class="flex ai-c jc-sb mb-1">
                  <div class="flex ai-c text-light">
                    <CalendarAltIcon class="mr-1"/>
                    Presença
                  </div>
                </div>
                <DatePicker v-model="process.datesGoodsPresenceDate"
                            :disabled="disableClick"
                            placeholder='Informar Data'
                            @input="() => inputUpdate('datesGoodsPresenceDate')"/>
              </div>

              <div>
                <div class="flex ai-c jc-sb mb-1">
                  <div class="flex ai-c text-light">
                    <CalendarAltIcon class="mr-1"/>
                    Carregamento
                  </div>
                </div>
                <DatePicker v-model="process.datesGoodsLoadingDate" disabled
                            placeholder='Não Informado'/>
              </div>
            </div>

            <div class="span-6">
              <div class="mb-2">
                <div class="flex ai-c jc-sb mb-1">
                  <div class="flex ai-c text-light">
                    <CalendarAltIcon class="mr-1"/>
                    Embarque
                  </div>
                </div>
                <DatePicker v-model="process.datesDepartureDate" disabled
                            placeholder='Não Informado'/>
              </div>

              <div class="mb-2">
                <div class="flex ai-c jc-sb mb-1">
                  <div class="flex ai-c text-light">
                    <CalendarAltIcon class="mr-1"/>
                    Chegada
                  </div>
                </div>
                <DatePicker v-model="process.datesArrivalDate" disabled
                            placeholder='Não Informado'/>
              </div>

              <div class="mb-2">
                <div class="flex ai-c jc-sb mb-1">
                  <div class="flex ai-c text-light">
                    <CalendarAltIcon class="mr-1"/>
                    Vistoria RFB
                  </div>
                </div>
                <DatePicker v-model="process.datesInspectionOfTheDIDate"
                            :disabled="disableClick"
                            placeholder='Informar Data'
                            @input="() => inputUpdate('datesInspectionOfTheDIDate')"/>
              </div>

              <div>
                <div class="flex ai-c jc-sb mb-1">
                  <div class="flex ai-c text-light">
                    <CalendarAltIcon class="mr-1"/>
                    Entr. Cliente
                  </div>
                </div>
                <DatePicker v-model="process.datesCustomerDeliverDate" disabled
                            placeholder='Não Informado'/>
              </div>
            </div>
          </div>
        </div>

        <div class="span-3 p-1 medium-border-radius flex fd-c" style="background-color: #F4F4F4;">
          <h6 class="text-bold text-center mb-2">MAPA</h6>
          <div class="mb-2">
            <div class="flex ai-c jc-sb mb-1">
              <div class="flex ai-c text-light">
                <CalendarAltIcon class="mr-1"/>
                Vistoria
              </div>
            </div>
            <DatePicker v-model="process.datesInspectionMAPADate"
                        :disabled="disableClick"
                        placeholder='Informar Data'
                        @input="() => inputUpdate('datesInspectionMAPADate')"/>
          </div>

          <div class="mb-2">
            <div class="flex ai-c jc-sb mb-1">
              <div class="flex ai-c text-light">
                <CalendarAltIcon class="mr-1"/>
                Liberação
              </div>
            </div>
            <DatePicker v-model="process.datesReleaseMAPADate"
                        :disabled="disableClick" placeholder='Informar Data'
                        @input="() => inputUpdate('datesReleaseMAPADate')"/>
          </div>

          <div class="mb-2">
            <div class="flex ai-c jc-sb mb-1">
              <div class="flex ai-c text-light">
                <CalendarAltIcon class="mr-1"/>
                Vínculo
              </div>
            </div>
            <DatePicker v-model="process.datesLinkInTheEnclosureDate"
                        :disabled="disableClick"
                        placeholder='Informar Data'
                        @input="() => inputUpdate('datesLinkInTheEnclosureDate')"/>
          </div>
        </div>
      </div>

      <div class="span-6 columns gap2">
        <div class="span-3 p-1 medium-border-radius" style="background-color: #F8F8F8;">
          <h6 class="text-bold text-center mb-2">CONHECIMENTO</h6>
          <div class="mb-2">
            <div class="flex ai-c jc-sb mb-1">
              <div class="flex ai-c text-light">
                <InfoIcon class="mr-1"/>
                Sit. Siscarga
              </div>
            </div>
            <!-- Verificar -->
            <Toggle
              v-model="process.datesSiscargaState"
              :disabled="disableClick"
              :labels="{
                checked: 'LIBERADO',
                unchecked: 'PENDENTE'
              }"
              name="sit-siscarga"
              @input="() => inputUpdate('datesSiscargaState')"
            />
          </div>

          <div class="mb-2">
            <div class="flex ai-c jc-sb mb-1">
              <div class="flex ai-c text-light">
                <CalendarAltIcon class="mr-1"/>
                Siscarga
              </div>
            </div>
            <DatePicker v-model="process.datesSiscargaDate"
                        :disabled="disableClick" @input="() => inputUpdate('datesSiscargaDate')"/>
          </div>

          <div class="mb-2">
            <div class="flex ai-c jc-sb mb-1">
              <div class="flex ai-c text-light">
                <CalendarAltIcon class="mr-1"/>
                Lib. AWB/ BL
              </div>
            </div>
            <DatePicker v-model="process.datesBillOfLadingReleaseDate"
                        :disabled="disableClick"
                        @input="() => inputUpdate('datesBillOfLadingReleaseDate')"/>
          </div>

          <div>
            <div class="flex ai-c jc-sb mb-1">
              <div class="flex ai-c text-light">
                <InfoIcon class="mr-1"/>
                TFA
              </div>
            </div>
            <!-- Verificar -->
            <Toggle
              v-model="process.datesTFASolved"
              :disabled="disableClick"
              :labels="{
                checked: 'LIBERADO',
                unchecked: 'PENDENTE'
              }"
              name="tfa"
              @input="() => inputUpdate('datesTFASolved')"
            />
          </div>
        </div>

        <div class="span-3 p-1 medium-border-radius" style="background-color: #F4F4F4;">
          <h6 class="text-bold text-center mb-2">TRANSFERÊNCIA</h6>
          <div v-if="process.datesIsThereDTCDTATransfer" class="mb-2">
            <div class="flex ai-c jc-sb mb-1">
              <div class="flex ai-c text-light">
                <InfoIcon class="mr-1"/>
                DTC / DTA
              </div>
            </div>
            <DatePicker v-model="process.datesDTCDTA"
                        :disabled="disableClick"
                        placeholder='Informar Data'
                        @input="() => inputUpdate('datesInspectionOfTheDIDate')"/>
          </div>

          <div v-if="process.datesThereIsDesova" class="mb-2">
            <div class="flex ai-c jc-sb mb-1">
              <div class="flex ai-c text-light">
                <InfoIcon class="mr-1"/>
                Desova
              </div>
            </div>
            <DatePicker v-model="process.datesDesova" :disabled="disableClick"
                        placeholder='Informar Data' @input="() => inputUpdate('datesDesova')"/>
          </div>

          <div class="mb-2">
            <div class="flex ai-c jc-sb mb-1">
              <div class="flex ai-c text-light">
                <CalendarAltIcon class="mr-1"/>
                Fat Recinto
              </div>
            </div>
            <DatePicker v-model="process.datesEnclosureBillingDate"
                        :disabled="disableClick"
                        placeholder='Informar Data'
                        @input="() => inputUpdate('datesEnclosureBillingDate')"/>
          </div>

          <div>
            <div class="flex ai-c jc-sb mb-1">
              <div class="flex ai-c text-light">
                <CalendarAltIcon class="mr-1"/>
                Comex
              </div>
            </div>
            <DatePicker v-model="process.datesComexDate"
                        :disabled="disableClick" placeholder='Informar Data'
                        @input="() => inputUpdate('datesComexDate')"/>
          </div>
        </div>

        <div class="span-6">
          <h6 class="text-bold text-center mb-2">DECLARAÇÃO DE IMPORTAÇÃO</h6>
          <div class="columns gap2 mb-2">

            <div class="span-6">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/>
                  Registro
                </div>
              </div>
              <DatePicker v-model="process.diRegistryDate"
                          :disabled="disableClick" placeholder='Informar Data'
                          @input="() => inputUpdate('diRegistryDate')"/>
            </div>

            <div class="span-6">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/>
                  Desembaraço
                </div>
              </div>
              <DatePicker v-model="process.diResourcefulnessDate"
                          :disabled="disableClick"
                          placeholder='Informar Data'
                          @input="() => inputUpdate('diResourcefulnessDate')"/>
            </div>

            <div class="span-6">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <InfoIcon class="mr-1"/>
                  Número da DI
                </div>
              </div>
              <input v-model="process.diNumber" v-mask="'##/#######-##'" :disabled="disableClick"
                     placeholder='Informar Número' type="text"
                     @click="consultDi" @input="() => inputUpdate('diNumber')"/>
            </div>

            <div class="span-6">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <InfoIcon class="mr-1"/>
                  Protocolo
                </div>
              </div>
              <input v-model="process.diProtocol" :disabled="disableClick"
                     placeholder='Informar Protocolo' type="text"
                     @input="() => inputUpdate('diProtocol')"/>
            </div>

          </div>

          <!--          <div class="mb-2">-->
          <!--            <div class="flex ai-c jc-sb mb-1">-->
          <!--              <div class="flex ai-c text-light">-->
          <!--                <InfoIcon class="mr-1" />-->
          <!--                Nome do Despachante-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            &lt;!&ndash; Verificar &ndash;&gt;-->
          <!--            <input v-model="process.diNameOfPersonInCharge" @input="() => inputUpdate('diNameOfPersonInCharge')" type="text" :disabled="disableClick" />-->
          <!--          </div>-->

          <!-- Verificar -->
          <div class="flex ai-c jc-sb p-2 medium-border-radius" style="background-color: #F8F8F8;">
            <div>
              <DoubleCheckIcon
                :class="
                  process.diChannel === 'VERDE'
                    ? 'text-success'
                    : process.diChannel === 'AMARELO'
                    ? 'text-warning'
                    : process.diChannel === 'VERMELHO'
                    ? 'text-danger'
                    : process.diChannel === 'CINZA'
                    ? ''
                    : ''
                "
                size="3rem"
              />
            </div>
            <div class="flex1 text-center">
              <h6 class="text-bold text-light">DESEMBARAÇADO</h6>
              <p
                v-if="process.diChannel"
                :class="
                  process.diChannel === 'VERDE'
                    ? 'text-success'
                    : process.diChannel === 'AMARELO'
                    ? 'text-warning'
                    : process.diChannel === 'VERMELHO'
                    ? 'text-danger'
                    : process.diChannel === 'CINZA'
                    ? ''
                    : ''
                "
              >
                Canal <strong>{{ process.diChannel }}</strong>
              </p>
              <p>Nenhum canal informado</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mask } from 'vue-the-mask';
import moment from 'moment';
import api from '@/services/api';

import {
  BuildingIcon,
  InvoiceIcon,
  IdCardIcon,
  CalendarAltIcon,
  InfoIcon,
  DoubleCheckIcon,
  IndustryIcon,
  FileIcon,
  MapPinIcon,
  BoxesIcon,
} from '@/components/Icons';
import Toggle from '@/components/DataInput/Toggle.vue';
import DatePicker from '@/components/DataInput/DatePicker.vue';

export default {
  name: 'ModalProcessTabGeneral',
  directives: {
    mask,
  },
  components: {
    // Icons
    BuildingIcon,
    InvoiceIcon,
    IdCardIcon,
    CalendarAltIcon,
    InfoIcon,
    DoubleCheckIcon,
    IndustryIcon,
    FileIcon,
    MapPinIcon,
    BoxesIcon,
    // Componenets
    Toggle,
    DatePicker,
  },
  props: {
    process: {
      type: Object,
      default: () => {
      },
    },
    activeTab: {
      type: Number,
      default: 0,
    },
    disableClick: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      changed: false,
    };
  },
  methods: {
    formatData(data) {
      return moment(data).format('DD/MM/YYYY');
    },
    ...mapActions(['toggleLoading']),
    ...mapActions('process', ['patchProcess']),
    async inputUpdate(field) {
      if (this.changed) {
        const data = {
          identification: this.process.identification,
          fieldUpdated: field,
          data: this.process,
        };
        await this.patchProcess(data);
      }
    },
    async consultDi() {
      if (this.process.diNumber.length === 13) {
        this.toggleLoading(true);
        const response = await api.get(`/integrations/myimp/consultarDi?processId=${this.process.id}&diIdentification=${this.process.diNumber}`);

        if (response.data) {
          this.process.diRegistryDate = response.data.registryDate;
          this.process.diResourcefulnessDate = response.data.resourcefulnessDate;

          this.$toast.success('Dados encontrados com sucesso!');
        } else {
          this.$toast.error('Nenhuma informação encontrada');
        }

        this.toggleLoading(false);
      }
    },
  },
  updated() {
    this.changed = true;
  },
};
</script>

<style scoped></style>
