import Swal from 'sweetalert2';
// eslint-disable-next-line import/no-cycle
import processStore from '@/store/Process';
import authStore from '@/store/Auth';

export const actions = {
  OPEN_PROC: 'openProc',
  CLOSE_PROC: 'closeProc',
  REQUEST_PROC: 'reqProc',
  UPDATE_PROC: 'updProc',
  ACCEPT_PROC: 'accProc',
  REJECT_PROC: 'rejProc',
};

export const methods = {
  openProc: (data, state) => {
    state.openedProcesses = data.data;
  },
  closeProc: (data, state) => {
    state.openedProcesses = data.data;
  },
  reqProc: (data, state) => {
    const reqData = data.data[Object.keys(data.data)[0]];

    Swal.fire({
      title: 'Solicitação de acesso ao Processo',
      html: `<p>O usuário ${reqData.name} está solicitando acesso a este processo.</p>`,
      type: 'warning',
      showConfirmButton: true,
      confirmButtonText: 'Liberar',
      showCancelButton: true,
      cancelButtonText: 'Não liberar',
    }).then((result) => {
      if (result.value) {
        state.socket.send(
          JSON.stringify({
            action: actions.CLOSE_PROC,
            data: {
              [Object.keys(data.data)[0]]: authStore.state.user.person.name,
            },
          }),
        );

        state.socket.send(
          JSON.stringify({
            action: actions.ACCEPT_PROC,
            data: {
              [Object.keys(data.data)[0]]: reqData,
            },
          }),
        );

        processStore.commit('ProcessStore/HIDE_PROCESS');
      } else {
        state.socket.send(
          JSON.stringify({
            action: actions.REJECT_PROC,
            data: {
              [Object.keys(data.data)[0]]: reqData,
            },
          }),
        );
      }
    });
  },
  accProc: () => {
    Swal.fire({
      title: 'Solicitação de acesso ao Processo',
      html: '<p>O processo foi liberado.</p>',
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Ok',
    });
  },
  rejProc: () => {
    Swal.fire({
      title: 'Solicitação de acesso ao Processo',
      html: '<p>O usuário ainda está editando o processo e precisa de mais tempo.</p>',
      type: 'error',
      showConfirmButton: true,
      confirmButtonText: 'Ok',
    });
  },
  updProc: (data, state) => {
    const id = Object.keys(data.data)[0];
    const key = Object.keys(data.data[id])[0];
    const value = data.data[id][key];
    // console.log(id, key, value);

    if (state.process && Number(state.process.id) === Number(id)) {
      state.process = {
        ...state.process,
        [key]: value,
      };
    }

    const newStateProcesses = state.processes.map((proc) => {
      if (Number(proc.id) === Number(id)) {
        return {
          ...proc,
          [key]: value,
        };
      }
      return proc;
    });

    state.processes = newStateProcesses;
  },
};

export function handleSocketMessage(event, state) {
  const data = JSON.parse(event.data);
  methods[data.action](data, state);

  // dispatchEvent(new CustomEvent('wsMessage'));
}

export function handleSocketError(error) {
  console.error('Erro no Websocket:', error);
}
