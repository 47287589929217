<template>
  <div class="table-overflow">
    <table :class="[tableClass, useFooter == false ? 'no-footer' : '', tdAuto == true ? 'td-auto' : '']">
      <thead>
        <th
          v-for="column in columns"
          :key="column.name"
          :class="headCentered && 'text-center'"
        >
          {{ column.name }}
        </th>
      </thead>
      <tbody>
        <tr v-if="useFilter == true">
          <td v-for="column in columns" :key="column.key">
            <input type="text" :placeholder="column.name" @input="(event) => {
              searchTerm = event.target.value;
              columnToFilter = event.target.value != '' ? column.key : '';
              filterData()
            }">
          </td>
        </tr>
        <tr v-for="item in clonedData" :key="item.id" @click="trAction(item)">
          <td v-for="({ name, key, ...props }) in columns" :key="name" :class="cellCentered && 'text-center'">
            <slot :name="key" :item="item" v-if="$scopedSlots[key]"></slot>
            <span v-else-if="key">
              <span v-if="props.isDate && key">
                {{ traverse(item, key) | moment("DD/MM/YYYY") }}
              </span>
              <span v-else>
                {{ traverse(item, key) }}
              </span>
            </span>
            <div class="flex" v-else-if="key == 'actions' && actions">
              <slot name="actions" :item="item"></slot>
            </div>
          </td>
        </tr>
        <tr v-if="clonedData.length <= 0">
          <td :colspan="columns.length">
            <h4 class="text-center">
              Nenhum registro até o momento
            </h4>
          </td>
        </tr>
      </tbody>
      <tfoot v-if="useFooter == true">
        <th :colspan="columns.length">
          <slot name="footer"></slot>
        </th>
      </tfoot>
    </table>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Table',
  // computed: {
  //   filteredData() {
  //     return this.data.filter((item) => String(item[`${this.columnToFilter}`]).match(new RegExp(this.searchTerm, 'gi')));
  //   },
  // },
  data() {
    return {
      searchTerm: '',
      columnToFilter: '',
      columnToSort: '',
      sortDirection: '',
      clonedData: this.data,
    };
  },
  methods: {
    traverse(obj, keys) {
      return keys.split('.').reduce((cur, key) => cur[key], obj);
    },
    filterData() {
      const column = this.columns.find((col) => col.key === this.columnToFilter);
      if (column !== undefined && column.isDate) {
        this.clonedData = this.data.filter((item) => moment(item[`${this.columnToFilter}`]).format('DD/MM/YYYY').match(new RegExp(this.searchTerm, 'gi')));
      } else {
        this.clonedData = this.data.filter((item) => String(item[`${this.columnToFilter}`]).match(new RegExp(this.searchTerm, 'gi')));
      }
    },
  },
  watch: {
    data: {
      handler(val) {
        this.clonedData = val;
      },
      deep: true,
    },
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    trAction: {
      type: Function,
      default: () => {},
    },
    actions: {
      type: Boolean,
      default: false,
    },
    tableClass: {
      type: String,
      default: '',
    },
    headCentered: {
      type: Boolean,
      default: false,
    },
    cellCentered: {
      type: Boolean,
      default: false,
    },
    useFooter: {
      type: Boolean,
      default: false,
    },
    useFilter: {
      type: Boolean,
      default: false,
    },
    tdAuto: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
  input[type="text"] {
    font-size: 0.8rem;
  }

  tr td {
    transition: var(--all-transition);
  }

  tr:hover td {
    background-color: var(--light-bg-color);
  }
</style>
