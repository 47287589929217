<template>
  <svg :width="size" :height="size" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.7656 7.5C14.7656 11.5136 11.5124 14.7656 7.5 14.7656C3.48759 14.7656 0.234375 11.5136 0.234375 7.5C0.234375 3.48876 3.48759 0.234375 7.5 0.234375C11.5124 0.234375 14.7656 3.48876 14.7656 7.5ZM7.5 8.96484C6.75571 8.96484 6.15234 9.56821 6.15234 10.3125C6.15234 11.0568 6.75571 11.6602 7.5 11.6602C8.24429 11.6602 8.84766 11.0568 8.84766 10.3125C8.84766 9.56821 8.24429 8.96484 7.5 8.96484ZM6.22052 4.12072L6.43784 8.1051C6.44801 8.29154 6.60217 8.4375 6.78888 8.4375H8.21112C8.39783 8.4375 8.55199 8.29154 8.56216 8.1051L8.77948 4.12072C8.79047 3.91934 8.63013 3.75 8.42845 3.75H6.57152C6.36984 3.75 6.20953 3.91934 6.22052 4.12072Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'InfoIcon',
  props: {
    size: {
      type: String,
      default: '1rem',
    },
  },
};
</script>
