<template>
  <ul class="tabs flex ai-c fgap2">
    <li :class="['flex ai-c jc-c', activeTab == 1 ? 'active' : '']" @click="$emit('tabClick', 1)">
      <span>Dados Gerais</span>
    </li>
    <li :class="['flex ai-c jc-c', activeTab == 2 ? 'active' : '']" @click="$emit('tabClick', 2)">
      <span>Logística</span>
      <span class="tag logistica ml-1">{{ process.containers.length }}</span>
    </li>
    <li :class="['flex ai-c jc-c', activeTab == 4 ? 'active' : '']" @click="$emit('tabClick', 4)">
      <span>Documentos</span>
      <span class="tag documentos ml-1">{{ docs ? docs.length : 0 }}</span>
    </li>
    <li :class="['flex ai-c jc-c', activeTab == 5 ? 'active' : '']" @click="$emit('tabClick', 5)">
      <span>Histórico</span>
      <span class="tag importacao ml-1">{{ process.history.length }}</span>
    </li>
    <!-- <li v-if="process.customer.id === 100"
        :class="['flex ai-c jc-c', activeTab == 6 ? 'active' : '']" @click="$emit('tabClick', 6)">
      <span>Integração</span>
    </li> -->
  </ul>
</template>

<script>

export default {
  name: 'ModalProcessTabList',
  props: {
    process: {
      type: Object,
      default: () => {
      },
    },
    activeTab: {
      type: Number,
      default: 1,
    },
    docs: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.modal-tabs .tabs {
  position: relative;
  margin-bottom: -1px;
  overflow: auto;
}

.modal-tabs .tabs li {
  flex: 1 1 auto;
  padding: 0.75rem;
  border-top-left-radius: var(--medium-border-radius);
  border-top-right-radius: var(--medium-border-radius);
  border-left: var(--small-border-width) solid transparent;
  border-right: var(--small-border-width) solid transparent;
  border-top: var(--small-border-width) solid transparent;
  cursor: pointer;
  height: 50px;
}

.modal-tabs .tabs li.active {
  background-color: var(--white-color);
  border-left: var(--input-border);
  border-right: var(--input-border);
  border-top: var(--input-border);
}

.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: var(--round-border-radius);
  color: var(--white-color);
}

.tag.logistica {
  background-color: var(--accent-color);
}

.tag.importacao {
  background-color: var(--primary-color);
}

.tag.documentos {
  background-color: var(--light-text-color);
}
</style>
