<template>
  <div class="flex ai-c">
    <h2 class="mr-2">{{pageTitle}}</h2>
    <div class="flex ai-c text-sm">
      <router-link :to="{ name: 'Home' }" class="text-light text-thin">
        Portal dos Parceiros
      </router-link>

      <span class="text-light mh-1">/</span>

      <p class="text-primary">{{breadcrumbName}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    breadcrumbName: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>

</style>
