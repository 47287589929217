<template>
  <svg :width="size" :height="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.5592 6.3977L13.125 9.85582V7.18863C13.125 6.44973 12.3074 6.00117 11.6842 6.3977L6.25 9.85582V2.1875C6.25 1.66973 5.83027 1.25 5.3125 1.25H0.9375C0.419727 1.25 0 1.66973 0 2.1875V17.8125C0 18.3303 0.419727 18.75 0.9375 18.75H19.0625C19.5803 18.75 20 18.3303 20 17.8125V7.18863C20 6.44969 19.1824 6.00117 18.5592 6.3977Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IndustryIcon',
  props: {
    size: {
      type: String,
      default: '1rem',
    },
  },
};
</script>
