import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/Auth';
import process from '@/store/Process';
import customer from '@/store/Customer';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    hideHeader: false,
    hideSidebar: false,
    connections: [],
  },
  mutations: {
    UPDATE_LOADING(state, payload) {
      state.loading = payload;
    },
    UPDATE_HEADER(state, payload) {
      state.hideHeader = payload;
    },
    UPDATE_SIDEBAR(state, payload) {
      state.hideSidebar = payload;
    },
    UPDATE_CONNECTIONS(state, payload) {
      state.connections = payload;
    },
  },
  actions: {
    toggleLoading(context, payload) {
      context.commit('UPDATE_LOADING', payload);
    },
    toggleHideHeader(context, payload) {
      context.commit('UPDATE_HEADER', payload);
    },
    toggleHideSidebar(context, payload) {
      context.commit('UPDATE_SIDEBAR', payload);
    },
    updateConnections(context, payload) {
      context.commit('UPDATE_CONNECTIONS', payload);
    },
  },
  modules: {
    auth,
    process,
    customer,
  },
});
