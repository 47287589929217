<template>
  <table class="text-sm primary striped">
    <thead>
      <tr>
        <th>CAMPO</th>
        <th>REGRA</th>
        <th>COR</th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td>Seguro</td>
        <td>
          ETA entre 0 (hoje) e 7 dias adiante se o seguro não estiver
          preenchido.
        </td>
        <td style="background-color: #E74C3C;">&nbsp;</td>
      </tr>
      <tr>
        <td>Numerário</td>
        <td>
          ETD menor ou igual o número de dias preenchido no cadastro do cliente (QUANTIDADE DIAS P/ NOTIFICAÇÃO DO NUMERÁRIO).
        </td>
        <td style="background-color: #2ECC71;">&nbsp;</td>
      </tr>
      <tr>
        <td>Encerramento Despachante</td>
        <td>
          Carregamento realizado há 3 dias ou mais.
        </td>
        <td style="background-color: #E67E22;">&nbsp;</td>
      </tr>
      <tr>
        <td>ETA</td>
        <td>
          ETA maior do que 7 e menor ou igual a 14 dias adiante.
        </td>
        <td style="background-color: #F1C40F;">&nbsp;</td>
      </tr>
      <tr>
        <td>ETA</td>
        <td>
          ETA maior do que 0 (hoje) e menor ou igual a 7 dias adiante.
        </td>
        <td style="background-color: #E74C3C;">&nbsp;</td>
      </tr>
      <tr>
        <td>ETA</td>
        <td>
          ETA igual a 0 dias (hoje).
        </td>
        <td style="background-color: #9B59B6;">&nbsp;</td>
      </tr>
      <tr>
        <td>DI</td>
        <td>
          Chegada a mais do que 2 dias.
        </td>
        <td style="background-color: #E67E22;">&nbsp;</td>
      </tr>
      <tr>
        <td>Carregamento</td>
        <td>
          Desembaraço a mais do que 2 dias.
        </td>
        <td style="background-color: #E67E22;">&nbsp;</td>
      </tr>
      <tr>
        <td>Documentos</td>
        <td>
          ETA menor ou igual a 14 dias adiante.
        </td>
        <td style="background-color: #E67E22;">&nbsp;</td>
      </tr>
      <tr>
        <td>Entrega</td>
        <td>
          Carregamento menor que 1 dia adiante.
        </td>
        <td style="background-color: #E67E22;">&nbsp;</td>
      </tr>
      <tr>
        <td>DTC / DTA</td>
        <td>
          Se tiver DTC/DTA e Data de DTC/DTA não estiver preenchida.
        </td>
        <td style="background-color: #E67E22;">&nbsp;</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'Legends',
};
</script>

<style>
</style>
