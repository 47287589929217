<template>
  <div v-if="activeTab == 5">
    <div class="flex ai-c jc-sb fgap2">
      <div>
        <h4 class="text-bold">HISTÓRICO</h4>
        <p>
          Total de {{ process.history.length }} histórico{{
            process.history.length > 1 ? "s" : ""
          }}
          cadastradas
        </p>
      </div>

      <div>
        <div class="flex ai-c fgap1 mb-1">
          <SearchIcon />
          <p>Pesquisar Histórico</p>
        </div>
        <div class="search-docs-container relative">
          <input
            type="search"
            name="historySearch"
            class="search-docs-input"
            v-model="historySearch"
            style="height: 35px; padding-right: 120px;"
          />
          <button type="button" class="btn solid primary">PESQUISAR</button>
        </div>
      </div>
    </div>

    <hr class="mb-2" />

    <HistoryItem
      :data="{
        personName: item.createdBy,
        date: item.created,
        title: '',
        description: item.description
      }"
      v-for="(item, index) in historyResults"
      :key="index"
    />
  </div>
</template>

<script>
import {
  SearchIcon,
} from '@/components/Icons';

import HistoryItem from '@/components/HistoryItem.vue';

export default {
  name: 'ModalProcessTabGeneral',
  components: {
    // Icons
    SearchIcon,
    // Componenets
    HistoryItem,
  },
  data() {
    return {
      historySearch: '',
    };
  },
  computed: {
    historyResults() {
      if (this.historySearch) {
        const result = [];

        for (let i = 0; i < Object.keys(this.process.history).length; i += 1) {
          const item = this.process.history[i];
          let returnThis = false;

          for (let y = 0; y < Object.keys(item).length; y += 1) {
            const key = String(Object.values(item)[y]);
            if (new RegExp(this.historySearch, 'gi').test(key)) {
              returnThis = true;
            }
          }

          if (returnThis) {
            result.push(item);
          }
        }

        return result;
      }
      return this.process.history;
    },
  },
  props: {
    process: {
      type: Object,
      default: () => {},
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.search-docs-input {
  min-width: 400px;
}

.search-docs-container button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 35px;
  padding: 0 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
