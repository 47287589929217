import router from '@/router';

const store = {
  namespaced: true,
  state: {
    user: {
      account: {},
      person: {},
    },
  },
  mutations: {
    UPDATE_USER(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    updateUser(context, payload) {
      context.commit('UPDATE_USER', payload);
    },
    logout(context) {
      localStorage.removeItem('@GSCMX:access_token');
      localStorage.removeItem('@GSCMX:refresh_token');
      context.commit('UPDATE_USER', {});
      router.push('/login');
    },
  },
};

export default store;
