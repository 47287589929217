<template>
  <div class="flex jc-fs">
    <label :class="['pointer toggle', value ? colors.checked : colors.unchecked]">
      <input
        type="checkbox"
        :name="name"
        :id="name"
        :checked="value"
        :value="value"
        @input="$emit('input', $event.target.checked)"
        :disabled="disabled"
      >
      {{ value ? labels.checked : labels.unchecked }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {},
    labels: {
      type: Object,
      required: false,
      default: () => ({
        checked: 'ligado',
        unchecked: 'desligado',
      }),
    },
    colors: {
      type: Object,
      required: false,
      default: () => ({
        checked: 'success',
        unchecked: 'danger',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
  .toggle {
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: var(--medium-border-radius);
    padding: 0.2rem 0.5rem;
    color: var(--white-color);
    text-align: center;
    font-weight: 700;
    font-size: 1rem;
  }

  .toggle input {
    border: 0!important;
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
  }

  .toggle.danger {
    background-color: var(--danger-color);
  }

  .toggle.warning {
    background-color: var(--warning-color);
  }

  .toggle.success {
    background-color: var(--success-color);
  }
</style>
