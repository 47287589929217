<template>
  <div v-if="activeTab == 2">
    <div class="columns gap2">
      <div class="span-3 columns gap2">
        <div class="span-6">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              <BriefcaseIcon class="mr-1"/>
              Incoterm
            </div>
          </div>
          <input v-model="process.incoterm" disabled type="text"/>
        </div>

        <div class="span-6">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              <BoxesIcon class="mr-1"/>
              Tipo Embarque
            </div>
          </div>
          <input v-model="process.typeOfBoarding" disabled type="text"/>
        </div>
      </div>

      <div class="span-3">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <BoxesIcon class="mr-1"/>
            Via de Transporte
          </div>
        </div>
        <input v-model="process.wayOfTransport" disabled type="text"/>
      </div>

      <div class="span-3">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <ShipIcon class="mr-1"/>
            Veículo / Navio
          </div>
        </div>
        <input :value="process.vehicle" disabled type="text"/>
      </div>

      <div class="span-3 columns gap2">
        <div class="span-6">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              <FileIcon class="mr-1"/>
              BL/AWB/CRT
            </div>
          </div>
          <input :value="process.billOfLading" disabled type="text"/>
        </div>

        <div class="span-6">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              <FileIcon class="mr-1"/>
              Freetime
            </div>
          </div>
          <input v-model="process.freetime" disabled type="text"/>
        </div>
      </div>

      <div class="span-3">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <IndustryIcon class="mr-1"/>
            Forwarder
          </div>
        </div>
        <input
          v-if="process.freightForwarder"
          v-model="process.freightForwarder.completeName"
          disabled
          type="text"
        />
        <input v-else disabled type="text" value="N/I"/>
      </div>

      <div class="span-3">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <IndustryIcon class="mr-1"/>
            Porto de Destino
          </div>
        </div>
        <input
          v-if="process.freightForwarder"
          v-model="process.destinationPort.alias"
          disabled
          type="text"
        />
        <input v-else disabled type="text" value="N/I"/>
      </div>

      <div class="span-3">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <IndustryIcon class="mr-1"/>
            Recinto Alfandegado
          </div>
        </div>
        <input
          v-if="process.freightForwarder"
          v-model="process.customsEnclosure.alias"
          disabled
          type="text"
        />
        <input v-else disabled type="text" value="N/I"/>
      </div>

      <div class="span-3">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <IndustryIcon class="mr-1"/>
            Transportadora
          </div>
        </div>
        <input
          v-if="process.conveyor"
          v-model="process.conveyor.completeName"
          disabled
          type="text"
        />
        <input v-else disabled type="text" value="N/I"/>
      </div>

      <div class="span-3">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <IndustryIcon class="mr-1"/>
            Armazém Geral
          </div>
        </div>
        <input
          v-if="process.generalWarehouse"
          v-model="process.generalWarehouse.completeName"
          disabled
          type="text"
        />
        <input v-else disabled type="text" value="N/I"/>
      </div>

      <div class="span-3">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <MapPinIcon class="mr-1"/>
            Nº CE Mercante
          </div>
        </div>
        <input
          v-model="process.ceMercante"
          :disabled="disableClick"
          type="text"
          @input="() => inputUpdate('ceMercante')"
        />
      </div>

      <div class="span-3">
        <div class="flex ai-c jc-sb mb-1">
          <div class="flex ai-c text-light">
            <ShipIcon class="mr-1"/>
            Navio de Embarque
          </div>
        </div>
        <input
          v-model="process.vehicleTranshipment"
          :disabled="disableClick"
          type="text"
          @input="() => inputUpdate('vehicleTranshipment')"
        />
      </div>
    </div>

    <hr class="mv-2"/>

    <div class="mb-2">
      <h5 class="text-bold">CONTAINERS</h5>
      <p>(Total de {{ process.containers.length }} containers cadastrados)</p>
    </div>

    <div class="offwhite-bg medium-border-radius p-1">
      <div class="table-overflow">
        <table class="process-table">
          <thead>
          <tr>
            <th>Container</th>
            <th>Chegada</th>
            <th>Freetime</th>
            <th>Deadline</th>
            <th>Devolução</th>
            <th>Demurrage</th>
            <th>Reparo</th>
            <th>Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(container, index) in containers" :key="index">
            <td>
              <Input
                v-model="containers[index].container"
                :disabled="disableClick"
                :required="true"
                className="text-bold"
                name="container"
                placeholder="Container"
                type="text"
              />
            </td>
            <td>
              <DatePicker v-model="containers[index].arrival" :disabled="disableClick"
                          :fullHeight="true"/>
            </td>
            <td>
              <Input
                v-model="containers[index].freetime"
                :disabled="disableClick"
                :required="true"
                className="text-bold"
                name="freetime"
                placeholder="Freetime"
                type="text"
              />
            </td>
            <td>
              <DatePicker v-model="containers[index].deadline" :disabled="disableClick"
                          :fullHeight="true"/>
            </td>
            <td>
              <DatePicker v-model="containers[index].devolution" :disabled="disableClick"
                          :fullHeight="true"/>
            </td>
            <td>
              <Input
                v-model="containers[index].demurrage"
                :disabled="disableClick"
                :money="true"
                :required="true"
                className="text-bold"
                name="demurrage"
                placeholder="Demurrage"
                type="text"
              />
            </td>
            <td>
              <Input
                v-model="containers[index].repair"
                :disabled="disableClick"
                :money="true"
                :required="true"
                className="text-bold"
                name="repair"
                placeholder="Reparo"
                type="text"
              />
            </td>
            <td>
              <a :style="`${disableClick ? 'pointer-events: none;' : ''}`"
                 class="btn small solid danger" href="#"
                 @click.prevent="removeContainer(containers[index].id)">
                Remover
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <Input
                v-model="newContainerData.container"
                :disabled="disableClick"
                :required="true"
                className="text-bold"
                name="container"
                placeholder="Container"
                type="text"
              />
            </td>
            <td>
              <DatePicker v-model="newContainerData.arrival" :disabled="disableClick"
                          :fullHeight="true"/>
            </td>
            <td>
              <Input
                v-model="newContainerData.freetime"
                :disabled="disableClick"
                :required="true"
                className="text-bold"
                name="freetime"
                placeholder="Freetime"
                type="text"
              />
            </td>
            <td>
              <DatePicker v-model="newContainerData.deadline" :disabled="disableClick"
                          :fullHeight="true"/>
            </td>
            <td>
              <DatePicker v-model="newContainerData.devolution" :disabled="disableClick"
                          :fullHeight="true"/>
            </td>
            <td>
              <Input
                v-model="newContainerData.demurrage"
                :disabled="disableClick"
                :money="true"
                :required="true"
                className="text-bold"
                name="demurrage"
                placeholder="Demurrage"
                type="text"
              />
            </td>
            <td>
              <Input
                v-model="newContainerData.repair"
                :disabled="disableClick"
                :money="true"
                :required="true"
                className="text-bold"
                name="repair"
                placeholder="Reparo"
                type="text"
              />
            </td>
            <td>
              <div class="flex ai-c fgap2">
                <a :style="`${disableClick ? 'pointer-events: none;': ''}`"
                   class="btn small solid success" href="#" @click.prevent="createContainer">Adicionar</a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import api from '@/services/api';

import {
  IndustryIcon,
  FileIcon,
  MapPinIcon,
  BoxesIcon,
  BriefcaseIcon,
  ShipIcon,
} from '@/components/Icons';

import DatePicker from '@/components/DataInput/DatePicker.vue';
import Input from '@/components/DataInput/Input.vue';

export default {
  name: 'ModalProcessTabLogistics',
  components: {
    // Icons
    IndustryIcon,
    FileIcon,
    MapPinIcon,
    BoxesIcon,
    BriefcaseIcon,
    ShipIcon,
    // Componenets
    DatePicker,
    Input,
  },
  data() {
    return {
      changed: false,
      newContainerData: {
        container: '',
        arrival: this.process.datesArrivalDate,
        freetime: this.process.freetime,
        deadline: moment.utc(this.process.datesArrivalDate).add(this.process.freetime - 1, 'days').format(),
        devolution: '',
        demurrage: '',
        repair: '',
      },
    };
  },
  methods: {
    ...mapActions('process', ['patchProcess']),
    async inputUpdate(field) {
      if (this.changed) {
        const data = {
          identification: this.process.identification,
          fieldUpdated: field,
          data: this.process,
        };
        await this.patchProcess(data);
      }
    },
    async removeContainer(id) {
      try {
        const response = await api.delete(`/api/public/partner/processes/${this.process.identification}/containers/${id}`);
        this.$toast.success(response.data.message);
        this.containers = this.containers.filter((item) => item.id !== id);
      } catch (err) {
        this.$toast.error('Não foi possível remover o container');
      }
    },
    async createContainer() {
      try {
        const response = await api.post(`/api/public/partner/processes/${this.process.identification}/containers`, {
          container: this.newContainerData.container,
          arrival: this.newContainerData.arrival,
          freetime: this.newContainerData.freetime,
          deadline: this.newContainerData.deadline,
          devolution: this.newContainerData.devolution,
          demurrage: parseFloat(this.newContainerData.demurrage.replace('.', '').replace(',', '.')),
          repair: parseFloat(this.newContainerData.repair.replace('.', '').replace(',', '.')),
        });

        this.newContainerData = {
          container: '',
          arrival: this.process.datesArrivalDate,
          freetime: this.process.freetime,
          deadline: moment.utc(this.process.datesArrivalDate).add(this.process.freetime - 1, 'days').format(),
          devolution: '',
          demurrage: '',
          repair: '',
        };
        this.containers.push(response.data.data);
      } catch (err) {
        this.$toast.error('Não foi possível adicionar o container');
      }
    },
  },
  updated() {
    this.changed = true;
  },
  watch: {
    containers: {
      handler(val) {
        if (this.timer) {
          clearTimeout(this.timer);
        }

        this.timer = setTimeout(async () => {
          const response = await api.put(`/api/public/partner/processes/${this.process.id}/containers`, {
            ...val[0],
            demurrage: parseFloat(val[0].demurrage.replace('.', '').replace(',', '.')),
            repair: parseFloat(val[0].repair.replace('.', '').replace(',', '.')),
          });
          this.containers.map((item) => {
            if (item.id === response.data.data.id) {
              return response.data.data;
            }
            return item;
          });
        }, 800);
      },
      deep: true,
    },
  },
  props: {
    process: {
      type: Object,
      default: () => {
      },
    },
    activeTab: {
      type: Number,
      default: 0,
    },
    containers: {
      type: Array,
      default: () => [],
    },
    disableClick: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped></style>
