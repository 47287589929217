<template>
  <svg :width="size" :height="size" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5192 11.645L17.7071 9.45709C18.2352 8.92897 18.0178 8.02675 17.306 7.79797L16 7.37819V3C16 2.44772 15.5523 2 15 2H13V0.75C13 0.335781 12.6642 0 12.25 0H7.75C7.33578 0 7 0.335781 7 0.75V2H5C4.44772 2 4 2.44772 4 3V7.37819L2.694 7.79797C1.98297 8.0265 1.76419 8.92838 2.29288 9.45709L4.48075 11.645C3.93213 13.009 2.67459 14 0.75 14C0.335781 14 0 14.3358 0 14.75V15.25C0 15.6642 0.335781 16 0.75 16C2.65697 16 4.10934 15.3559 5.22681 14.1439C5.67741 15.2323 6.75066 16 8 16H12C13.2493 16 14.3226 15.2323 14.7732 14.1439C15.8905 15.3558 17.3428 16 19.25 16C19.6642 16 20 15.6642 20 15.25V14.75C20 14.3358 19.6642 14 19.25 14C17.3495 14 16.0768 13.0312 15.5192 11.645ZM6 4H14V6.73534L10.306 5.548C10.107 5.48403 9.89299 5.48403 9.694 5.548L6 6.73534V4Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'ShipIcon',
  props: {
    size: {
      type: String,
      default: '1rem',
    },
  },
};
</script>
