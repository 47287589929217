<template>
  <div class="full-height flex">
    <Sidebar />
    <div class="dashboard-container flex1 p-2 flex fd-c fgap2">
      <Header v-if="!hideHeader"/>
      <transition name="pages" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Header from '@/components/Header.vue';
import Sidebar from '@/components/Sidebar.vue';

export default {
  name: 'Dashboard',
  components: {
    Sidebar,
    Header,
  },
  computed: {
    ...mapState(['hideHeader']),
  },
};
</script>

<style scoped>
  .dashboard-container {
    overflow: auto;
  }
</style>
