import { render, staticRenderFns } from "./ModalProcessHeader.vue?vue&type=template&id=105f3fdd&scoped=true"
import script from "./ModalProcessHeader.vue?vue&type=script&lang=js"
export * from "./ModalProcessHeader.vue?vue&type=script&lang=js"
import style0 from "./ModalProcessHeader.vue?vue&type=style&index=0&id=105f3fdd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "105f3fdd",
  null
  
)

export default component.exports