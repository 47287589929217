var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageTitle',{staticClass:"mb-2",attrs:{"breadcrumbName":"Meus Dados","pageTitle":"Meus Dados"}}),_c('div',{staticClass:"card flex fd-c fgap2"},[_vm._m(0),_c('div',{staticClass:"columns"},[_c('form',{staticClass:"columns gap2 ac-gs span-8 pr-3 user-form",on:{"submit":function($event){$event.preventDefault();return _vm.updateUserData.apply(null, arguments)}}},[_c('div',{staticClass:"span-6"},[_c('Input',{attrs:{"className":"text-bold","label":"Nome Completo","name":"name","placeholder":"Nome Completo"},model:{value:(_vm.userData.name),callback:function ($$v) {_vm.$set(_vm.userData, "name", $$v)},expression:"userData.name"}})],1),_c('div',{staticClass:"span-6"},[_c('Input',{attrs:{"className":"text-bold","label":"Email","name":"email","placeholder":"Email"},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}})],1),_c('div',{staticClass:"span-12"},[_c('Toggle',{attrs:{"labels":{
            checked: 'Alterar Senha',
            unchecked: 'Alterar Senha'
          },"name":"sit-siscarga"},model:{value:(_vm.changePassword),callback:function ($$v) {_vm.changePassword=$$v},expression:"changePassword"}})],1),_c('transition',{attrs:{"name":"pages"}},[(_vm.changePassword)?_c('div',{staticClass:"span-6"},[_c('Input',{attrs:{"hint":true,"className":"text-bold","hintMessage":"Sua senha deverá conter pelo menos um caractere especial ( ex.: !#$%ˆ&*()_? ) e pelo menos um dígito.","label":"Nova Senha","name":"password","placeholder":"Nova Senha","type":"password"},model:{value:(_vm.userData.password),callback:function ($$v) {_vm.$set(_vm.userData, "password", $$v)},expression:"userData.password"}})],1):_vm._e()]),_c('transition',{attrs:{"name":"pages"}},[(_vm.changePassword)?_c('div',{staticClass:"span-6"},[_c('Input',{attrs:{"hint":true,"className":"text-bold","hintMessage":"Repita a senha anterior","label":"Repita a Nova Senha","name":"rePassword","placeholder":"Repita a Nova Senha","type":"password"},model:{value:(_vm.userData.repeatedPassword),callback:function ($$v) {_vm.$set(_vm.userData, "repeatedPassword", $$v)},expression:"userData.repeatedPassword"}})],1):_vm._e()]),_vm._m(1)],1),_c('div',{staticClass:"span-4 pl-3"},[_vm._m(2),_c('ul',{staticClass:"company-list"},_vm._l((_vm.customBrokers),function(customer){return _c('CompanyList',{key:customer.id,attrs:{"customer":customer}})}),1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"mb-1"},[_vm._v("MEUS DADOS")]),_c('p',{staticClass:"text-light"},[_vm._v("Configurações dos seus dados no portal")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"span-12"},[_c('button',{staticClass:"btn solid primary full-width"},[_vm._v("ATUALIZAR MEUS DADOS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"medium-border-radius offwhite-bg pv-1 ph-2 flex ai-c jc-sb mb-2"},[_c('div',[_c('h4',{staticClass:"text-uppercase text-medium mb-1"},[_vm._v("Empresas Vinculadas")]),_c('p',{staticClass:"text-light"},[_vm._v("Empresas que você tem acesso")])])])
}]

export { render, staticRenderFns }