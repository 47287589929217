<template>
  <aside :class="[
    'main-sidebar flex fd-c jc-sb fgap2',
    hideSidebar ? 'hide' : ''
  ]">

    <div class="flex fd-c fgap2">

      <div class="text-center p-1">
        <router-link :to="{ name: 'Home' }">
          <img src="@/assets/img/logo.png" alt="Prime Internacional">
        </router-link>
      </div>

      <ul class="navigation">
        <p class="text-center text-light text-sm">Menu</p>
        <li>
          <router-link :to="{ name: 'Home' }">
            <HomeIcon size="3rem" />
          </router-link>
          <div class="nav-tooltip">
            <span class="text-medium">Dashboard</span>
          </div>
        </li>
        <li>
          <router-link :to="{ name: 'Processes' }">
            <ProcessIcon size="3rem" />
          </router-link>
          <div class="nav-tooltip">
            <span class="text-medium">Processos</span>
          </div>
        </li>
        <li>
          <router-link :to="{ name: 'ProcessesReport' }">
            <ReportIcon size="3rem" />
          </router-link>
          <div class="nav-tooltip">
            <span class="text-medium">Relatórios</span>
          </div>
        </li>
      </ul>

    </div>

  </aside>
</template>

<script>
import { mapState } from 'vuex';

import {
  HomeIcon,
  ProcessIcon,
  ReportIcon,
} from '@/components/Icons';

export default {
  name: 'Sidebar',
  components: {
    HomeIcon,
    ProcessIcon,
    ReportIcon,
  },
  computed: {
    ...mapState(['hideSidebar']),
  },
};
</script>

<style scoped>
  .main-sidebar {
    width: 80px;
    background-color: var(--white-color);
  }

  .main-sidebar .navigation a {
    display: block;
    text-align: center;
    padding: .75rem 1rem;
    opacity: .6;
    position: relative;
    transition: var(--all-transition);
  }

  .main-sidebar .navigation a:hover {
    color: var(--primary-color);
  }

  .main-sidebar .navigation a::before {
    content: '';
    display: block;
    position: absolute;
    top: .75rem;
    bottom: .75rem;
    left: 0;
    width: 2px;
    background-color: var(--primary-color);
    transition: var(--all-transition);
    opacity: 0;
  }

  .main-sidebar .navigation a.router-link-exact-active,
  .main-sidebar .navigation a.router-link-exact-active::before,
  .main-sidebar .navigation a:hover {
    opacity: 1;
  }

  .navigation li {
    position: relative;
  }

  .navigation li .nav-tooltip {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 90%;
    transform: translate3d(0, -50%, 0);
    background-color: white;
    z-index: 999;
    pointer-events: none;
    padding: 0.75rem;
    border-radius: var(--medium-border-radius);
    color: var(--primary-color);
    box-shadow: var(--box-shadow);
    border: 1px solid var(--primary-color);
    transition: var(--all-transition);
  }

  .navigation li .nav-tooltip::after,
  .navigation li .nav-tooltip::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 5px 10px 5px 0;
    border-color: transparent white transparent transparent;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
  }

  .navigation li .nav-tooltip::before {
    border-width: 6px 11px 6px 0;
    left: -11px;
    border-color: transparent var(--primary-color) transparent transparent;
  }

  .navigation li:hover .nav-tooltip {
    left: calc(100% + 10px);
    opacity: 1;
    visibility: visible;
  }
</style>
