import moment from 'moment';

/* eslint-disable quote-props */
const ProcessesReportColumnsExcel = {
  'Ref': 'identification',
  'Etapa': 'step.description',
  'Empresa': 'customer.name',
  'Exportador': 'exporter.name',
  'Ref Pedido': 'customerRef',
  'Outras Referencias': 'anotherRef',
  'Status': 'status',
  'Invoice': 'invoice',
  'Incoterm': 'incoterm',
  'CNTR': 'typeOfBoarding',
  'Prodidão Prevista': {
    field: 'datesEstimatedGoodsReadinesDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'ETD': {
    field: 'datesETD',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'ETA': {
    field: 'datesETA',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Embarque': {
    field: 'datesDepartureDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Chegada': {
    field: 'datesArrivalDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Nº DI': 'diNumber',
  'Registro': {
    field: 'diRegistryDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
};

export default ProcessesReportColumnsExcel;
