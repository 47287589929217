export default {
  UPDATE_PROCESS(state, payload) {
    state.process = payload;
  },
  CLEAR_PROCESS(state) {
    state.process = null;
  },
  UPDATE_PROCESSES(state, payload) {
    state.processes = payload;
  },
  UPDATE_STEPS(state, payload) {
    state.steps = payload;
  },
  UPDATE_STEPS_AMOUNT(state, payload) {
    state.stepsAmount = payload;
  },
  UPDATE_TABLE_COLUMNS(state, payload) {
    state.tableColumns = state.tableColumns.map((col) => {
      if (col.headerName === payload) {
        // eslint-disable-next-line no-param-reassign
        col.hide = !col.hide;
      }
      return col;
    });
  },
  UPDATE_JSON_DATA_CUSTOMER(state, payload) {
    state.process.jsonDataCustomer = payload;
  },
  UPDATE_GENERAL_FILTER(state, payload) {
    state.generalFilter = payload;
  },
  SYNC_TABLE_COLUMNS(state, payload) {
    state.tableColumns = state.tableColumns.map((col) => {
      if (payload.find((item) => item === col.field)) {
        // eslint-disable-next-line no-param-reassign
        col.hide = false;
      } else {
        // eslint-disable-next-line no-param-reassign
        col.hide = true;
      }
      return col;
    });
  },
  SORT_TABLE_COLUMNS(state, payload) {
    state.tableColumns = payload;
  },
  SET_NOCLICK(state, payload) {
    state.noClick = payload;
  },
  UPDATE_MODAL_ACTION(state, payload) {
    state.modalAction = payload;
  },
  UPDATE_MODAL_ACTION_TITLE(state, payload) {
    state.modalActionTitle = payload;
  },
  UPDATE_ACTIONS_MESSAGES(state, payload) {
    state.actionMessages = payload;
  },
  UPDATE_ACTION_FIELD(state, payload) {
    state.actionField = payload;
  },
  RESET_PROCESS_ACTION(state) {
    state.modalAction = false;
    state.modalActionTitle = '';
    state.actionMessages = [];
    state.actionField = '';
  },
  UPDATE_CONNECTIONS(state, payload) {
    state.connections = payload;
  },
};
