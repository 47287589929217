<template>
  <div class="login-page full-height columns gap2">
    <div class="span-5 p-4 text-center flex fd-c jc-c">
      <h1 class="mb-1 text-xg">Código de Verificação</h1>
      <p class="mb-3">Digite o Código de Verificação recebido em seu email para criar uma nova senha de acesso</p>

      <form class="flex fd-c fgap2" @submit.prevent="verifyCode">
        <div class="flex ai-c fgap2">
          <div class="code-container relative flex1">
            <input
              ref="inputCode"
              class="input-code"
              type="number"
              v-model="pin"
              @input="inputValue"
              style="opacity: 0;"
              @focus="showIndicator = true"
              @blur="showIndicator = false"
            >
            <div class="divider one"></div>
            <div class="divider two"></div>
            <div class="divider three"></div>
            <div class="divider four"></div>
            <div class="divider five"></div>

            <span class="code-value one">{{ pin.split('')[0] }}</span>
            <span class="code-value two">{{ pin.split('')[1] }}</span>
            <span class="code-value three">{{ pin.split('')[2] }}</span>
            <span class="code-value four">{{ pin.split('')[3] }}</span>
            <span class="code-value five">{{ pin.split('')[4] }}</span>
            <span class="code-value six">{{ pin.split('')[5] }}</span>

            <div v-if="showIndicator" class="text-indicator" :style="`left: ${pin.length === 0 ? 'calc(100% / 12)' : `${pin.length >= 6 ? `calc(((100% / 6) * ${pin.length -1}) + (100% / 12) + 10px)` : `calc(((100% / 6) * ${pin.length}) + (100% / 12))`}` }`"></div>
          </div>
        </div>

        <button type="submit" class="btn solid primary full-width extra-big" @click.prevent="() => verifyCode()">RECUPERAR SENHA</button>
      </form>

      <router-link :to="{ name: 'Login' }" class="mt-2">Voltar para login</router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

// eslint-disable-next-line import/no-cycle
import api from '@/services/api';

export default {
  name: 'RecoveryCode',
  data() {
    return {
      pin: '',
      showIndicator: false,
    };
  },
  methods: {
    ...mapActions(['toggleLoading']),
    async verifyCode() {
      this.toggleLoading(true);

      try {
        const response = await api.post('/credential/recovery/verifyCode', { pin: this.pin }, { headers: { Authorization: '' } });
        if (response && response.data.code === 'SUCCESS') {
          this.$toast.success(response.data.message, {
            position: 'top-center',
            pauseOnHover: true,
            dismissible: true,
            duration: 10000,
          });
          this.$router.push(`/redefinir-senha/${this.pin}`);
        } else {
          this.$toast.error(response);
        }
      } catch (err) {
        this.$toast.error(`Não foi possível executar esta ação no momento: ${err} `);
      }
      this.toggleLoading(false);
    },
    inputValue(event) {
      if (event.target.value.length > 6) {
        this.pin = event.target.value.substring(0, event.target.value.length - 1);
      }
    },
  },
};
</script>

<style scoped>
  .login-page {
    background: url("../../assets/img/bg-login.jpg") right center / auto 100% no-repeat, var(--white-color);
  }

  form {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
  }

  .code-container {
    border: var(--input-border);
    border-radius: var(--medium-border-radius);
  }

  .input-code {
    text-align: center;
    letter-spacing: 100%;
    color: transparent;
  }

  .input-code,
  .input-code::-webkit-outer-spin-button,
  .input-code::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .input-code::-webkit-outer-spin-button,
  .input-code::-webkit-inner-spin-button {
    margin: 0;
  }

  .divider {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: var(--input-border-color);
    pointer-events: none;
  }

  .divider.one {
    left: calc(100% / 6);
  }

  .divider.two {
    left: calc((100% / 6) * 2);
  }

  .divider.three {
    left: calc((100% / 6) * 3);
  }

  .divider.four {
    left: calc((100% / 6) * 4);
  }

  .divider.five {
    left: calc((100% / 6) * 5);
  }

  .code-value {
    position: absolute;
    top: 0;
    bottom: 0;
    line-height: 50px;
    font-size: 1.5rem;
    font-weight: 600;
    transform: translateX(-50%);
    pointer-events: none;
  }

  .code-value.one {
    left: calc(100% / 12);
  }

  .code-value.two {
    left: calc((100% / 12) * 3);
  }

  .code-value.three {
    left: calc((100% / 12) * 5);
  }

  .code-value.four {
    left: calc((100% / 12) * 7);
  }

  .code-value.five {
    left: calc((100% / 12) * 9);
  }

  .code-value.six {
    left: calc((100% / 12) * 11);
  }

  .text-indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: 40px;
    width: 1px;
    background-color: black;
    animation: .7s ease-in-out 0s infinite inputAnimation;
  }

  @keyframes inputAnimation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @media screen and (max-width: 1300px) {
    .login-page.columns {
      grid-template-columns: 1fr;
      background: var(--white-color);
    }
  }
</style>
