export function sanitizeProcessData(process) {
  const sanitizedData = {};

  Object.entries(process).map((item) => {
    const key = item[0];
    const value = item[1];

    if (value === null) {
      sanitizedData[key] = '';
      return item;
    }

    sanitizedData[key] = value;

    if (key === 'jsonDataCustomer') {
      sanitizedData[key] = JSON.parse(value);
    }

    return item;
  });

  return sanitizedData;
}

export function getTypeOfProcessReference(process) {
  if (process === null || process.identification === null) return 'N/I';

  let ref = '';
  let typeOfProcess = '';
  const zerosToLeft = 5;

  if (process.typeOfProcess !== null) {
    typeOfProcess = process.typeOfProcess;
  }

  const zero = zerosToLeft - process.identification.toString().length + 1;
  const zeroPad = Array(+(zero > 0 && zero)).join('0') + process.identification;

  if (typeOfProcess === 'ASSESSORIA') {
    ref = `<strong>A</strong>${zeroPad}`;
  } else if (typeOfProcess === 'ENCOMENDA') {
    ref = `<strong>E</strong>${zeroPad}`;
  } else {
    ref = `<strong>PRI</strong>${zeroPad}`;
  }

  return ref;
}
