<template>
  <div class="modal-header flex ai-c jc-sb fw-w">
    <div class="flex ai-c fgap2" >
      <h2 class="text-primary text-xg" v-html="getFormattedReference"></h2>
      <div v-if="process.step" class="tag-status" :style="`background-color: ${process.step.color}; color: ${process.step.textColor};`">
        {{ process.step.alias }}
      </div>
      <p class="text-light">STATUS: <span class="text-bg text-bold">"{{ process.status }}"</span></p>
    </div>

    <div class="flex ai-c fgap2">
      <div v-if="!!process.note || !!process.reminder">
        <button class="btn primary solid small" @click.prevent="drawerObs = true">
          Observações
        </button>
      </div>
      <div @click.prevent="$emit('request-close', true)" class="close-modal">X</div>
    </div>

    <div style="flex: 0 0 100%;" v-if="noClickMessage !== null" class="no-click-message flex ai-c text-light pointer">
      <IdCardIcon class="mr-1" /> {{ noClickMessage }}
    </div>

    <drawer-modal @request-close="drawerObs = false" :handler="drawerObs" title="Observações e Lembretes">
      <template #content>
        <div class="mb-4" v-if="process.note">
          <h4 class="text-bold mb-2">Observações</h4>
          <textarea name="note" id="note" disabled v-model="process.note"></textarea>
        </div>

        <div v-if="process.reminder">
          <h4 class="text-bold mb-2">Lembretes</h4>
          <textarea name="note" id="note" disabled v-model="process.reminder"></textarea>
        </div>
      </template>
    </drawer-modal>
  </div>
</template>

<script>
import {
  IdCardIcon,
} from '@/components/Icons';
import DrawerModal from './DrawerModal.vue';

export default {
  name: 'ModalProcessHeader',
  components: {
    IdCardIcon,
    DrawerModal,
  },
  data() {
    return {
      drawerObs: false,
    };
  },
  computed: {
    getFormattedReference() {
      return this.getTypeOfProcessReference();
    },
  },
  props: {
    process: {
      type: Object,
      default: () => {},
    },
    noClickMessage: {
      type: String,
      default: () => null,
    },
  },
  methods: {
    getTypeOfProcessReference() {
      if (this.process === null || this.process.identification === null) return 'N/I';

      let ref = '';
      let typeOfProcess = '';
      const zerosToLeft = 5;

      if (this.process.typeOfProcess !== null) {
        typeOfProcess = this.process.typeOfProcess;
      }

      if (typeOfProcess === 'ASSESSORIA') {
        ref = `<strong>A</strong>${this.zeroPad(this.process.identification, zerosToLeft)}`;
      } else if (typeOfProcess === 'ENCOMENDA') {
        ref = `<strong>E</strong>${this.zeroPad(this.process.identification, zerosToLeft)}`;
      } else {
        ref = `<strong>PRI</strong>${this.zeroPad(this.process.identification, zerosToLeft)}`;
      }

      return ref;
    },
    zeroPad(num, places) {
      const zero = places - num.toString().length + 1;

      return Array(+(zero > 0 && zero)).join('0') + num;
    },
  },
};
</script>

<style scoped>
  .close-modal {
    padding: 0 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
  }
  .modal-header {
    border-bottom: var(--input-border);
    padding: 0.5rem 0 1rem;
  }

  .tag-status {
    padding: 0.75rem 1.5rem;
    font-weight: 700;
    border-radius: var(--medium-border-radius);
    color: var(--white-color);
  }

  .no-click-message {
    /* background-color: var(--danger-color) !important; */
    padding: 0.5rem  1rem;
    color: #000000;
    border-radius: var(--round-border-radius);
    font-size: 0.9rem;
    font-weight: 700;
  }
</style>
