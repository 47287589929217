import Vue from 'vue';
import VueRouter from 'vue-router';

import Dashboard from '@/components/Dashboard.vue';

import Login from '../views/Auth/Login.vue';
import ForgotPassword from '../views/Auth/ForgotPassword.vue';
import RecoveryCode from '../views/Auth/RecoveryCode.vue';
import ResetPassword from '../views/Auth/ResetPassword.vue';

import Home from '../views/Home.vue';
import Processes from '../views/Processes.vue';
import MyData from '../views/MyData.vue';
import ProcessesReport from '../views/ProcessesReport.vue';
import ForwardersShippingQuote from '../views/Forwarders/ShippingQuote.vue';

Vue.use(VueRouter);

const routes = [{
  path: '/',
  component: Dashboard,
  children: [{
    path: '/',
    name: 'Home',
    component: Home,
    props: {
      pageTitle: 'Painel Geral dos Parceiros',
      breadcrumbName: 'Painel Geral',
    },
  },
  {
    path: '/processos/:step?',
    name: 'Processes',
    component: Processes,
    props: {
      pageTitle: 'Processos Disponíveis',
      breadcrumbName: 'Processos de Importação',
    },
  },
  {
    path: '/relatorio',
    name: 'ProcessesReport',
    component: ProcessesReport,
    props: {
      pageTitle: 'Relatório Geral',
      breadcrumbName: 'Relatório Geral',
    },
  },
  {
    path: '/meus-dados',
    name: 'MyData',
    component: MyData,
    props: {
      pageTitle: 'Meus Dados',
      breadcrumbName: 'Informações do seu usuário',
    },
  },
  ],
},
{
  path: '/login',
  name: 'Login',
  component: Login,
},
{
  path: '/esqueci-minha-senha',
  name: 'ForgotPassword',
  component: ForgotPassword,
},
{
  path: '/codigo-verificacao',
  name: 'RecoveryCode',
  component: RecoveryCode,
},
{
  path: '/redefinir-senha/:pin',
  name: 'ResetPassword',
  component: ResetPassword,
},
{
  path: '/forwarders/cotacao/:codigo',
  name: 'ForwardersShippingQuote',
  component: ForwardersShippingQuote,
  props: true,
},
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const openRoutes = [
  'Login',
  'ForgotPassword',
  'RecoveryCode',
  'ResetPassword',
  'ForwardersShippingQuote',
];

router.beforeEach((to, from, next) => {
  if ((to.name === 'Login' && localStorage.getItem('@GSCMX:access_token')) || (to.name === 'ForgotPassword' && localStorage.getItem('@GSCMX:access_token'))) {
    next({ name: 'Home' });
  } else if (openRoutes.find((route) => route === to.name) === to.name) {
    next();
  } else if (openRoutes.find((route) => route === to.name) !== to.name) {
    if (!localStorage.getItem('@GSCMX:access_token')) {
      next({ name: 'Login' });
    }
    next();
  } else {
    next();
  }
});

export default router;
