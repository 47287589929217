<template>
  <transition name="modal">
    <div v-if="visible" class="modal" style="background: rgba(0, 0, 0, 0.8)">
      <div :class="`${type}`" class="modal-content-wrapper">
        <div class="modal-content text-center">
          <p class="text-lg mb-2 text-bold">{{ title }}</p>
          <div v-html="text"></div>
        </div>

        <div class="modal-actions flex fw-w ai-c gap2 jc-c text-center">
          <a
            class="btn solid danger"
            href="#"
            @click="hide"
          >
            {{ cancelText }}
          </a>

          <a
            v-if="onConfirm"
            class="btn solid success"
            href="#"
            @click="confirm"
          >
            {{ confirmText }}
          </a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import Dialog from '@/plugins/dialog';

export default {
  name: 'DialogComponent',
  data() {
    return {
      visible: false,
      title: '',
      text: '',
      type: 'info',
      onConfirm: null,
      confirmText: 'CONFIRMAR',
      cancelText: 'CANCELAR',
    };
  },
  methods: {
    hide() {
      this.visible = false;
      this.type = 'info';
    },
    confirm() {
      if (typeof this.onConfirm === 'function') {
        this.onConfirm();
        this.hide();
      } else {
        this.hide();
      }
    },
    show(params) {
      this.visible = true;
      this.title = params.title;
      this.text = params.text;
      this.onConfirm = params.onConfirm;
      this.confirmText = params.confirmText ? params.confirmText : 'CONFIRMAR';
      this.cancelText = params.cancelText ? params.cancelText : 'CANCELAR';
    },
  },
  beforeMount() {
    Dialog.EventBus.$on('dialogType', (value) => {
      this.type = value;
    });
    Dialog.EventBus.$on('show', (params) => {
      this.show(params);
    });
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  inset: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content-wrapper {
  min-width: 40vw;
  max-height: 85vh;
  background-color: var(--white-color);
  border-radius: var(--medium-border-radius);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-top: 8px solid var(--primary-color);
}

.modal-content-wrapper.info {
  border-top: 8px solid var(--primary-color);
}

.modal-content-wrapper.danger {
  border-top: 8px solid var(--danger-color);
}

.modal-content-wrapper.success {
  border-top: 8px solid var(--success-color);
}

.modal-content-wrapper.full {
  width: 100%;
}

.modal-content {
  padding: 1.5rem;
  font-size: 1.1rem;
  color: var(--text-color);
  overflow: auto;
}

.modal-actions {
  padding: 1rem 1.5rem;
}

.modal-enter-active,
.modal-leave-active {
  transition: var(--all-transition);
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-to,
.modal-leave {
  opacity: 1;
}

@media screen and (max-width: 1020px) {
  .modal .modal-content-wrapper {
    max-width: 80vw !important;
  }
}
</style>
